import React from 'react'
import PropTypes from 'prop-types'
import imgPopup from '../../assets/pop-up.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function AdsPopup(props) {
    const { onClose, ...other } = props
    return (
        <div className='fixed inset-0 px-4 sm:px-0' style={{ background: "#36363694", zIndex: "9991" }}>
            <div className='md:p-7 rounded-md w-full xl:w-[600px] xl:mt-2 md:w-5/6 md:mt-16 mt-20 mx-auto relative' >
                <img src={imgPopup} className='xl:h-full xl:mx-auto' />
                <a className='absolute right-0 top-0 cursor-pointer' onClick={() => { onClose() }}>
                    <FontAwesomeIcon icon={faTimes} className='p-3 text-3xl ' />
                </a>
            </div>
        </div>
    )
}

AdsPopup.propTypes = {}

export default AdsPopup
