import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';

const data = [
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/AA-CORPORATION.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/SECOIN-SAIGON-INDUSTRY-INVESTMENT-JOINT-STOCK-COMPANY.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/PHUC-MY-GIA-CUSTOM-FURNITURE-COMPANY-LIMITED.png",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/NIPPON-PAINT-VIETNAM-CO-LTD.png",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/TAKARA-STANDARD-VN.png",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/MY-KHANG-TRADING-PRODUCCING-COMPANY-LIMITED.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/SAO-NAM-CO-LTD.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/DO-MI-PRODUCTION-AND-TRADING-COMPANY-LIMITED.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/GIA-KHANG-CONSTRUCTION-TRADING-COMPANY-LIMITED.png",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/JOINBOND-TRADING-PRODUCTION-COMPANY-LIMITED.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/KHANH-NGAN-CONTRUCSTION---TRADING---SERVICE-CO.LTD.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/PHUOC-KHANG-CO-LTD.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/SAGONIC-LTD.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/THIEN-AN-FURNITURE.png",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/VIET-THAI-ELECTRIC-CABLE-CORPORATION.jpg",
    process.env.REACT_APP_EXHIBITOR_IMAGE + "/images/VRG-KIEN-GIANG-MDF-JOINT-STOCK-COMPANY.jpg",
]

function SliderExhibitor(props) {
    return (
        <>
            {data != null && data.length > 0 && (
                <>
                    <div className='hidden md:block'>
                        <Swiper watchSlidesProgress={true} slidesPerView={4}
                            navigation={true} pagination={{
                                clickable: true,
                            }} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='size-72 box-border flex items-center p-5'>
                                            <div className='rounded-lg mx-auto p-3 overflow-hidden'>
                                                <img className='w-full' src={item} style={{ height: "auto" }} />
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='md:hidden'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='rounded-lg w-full h-auto md:h-52 mb-2 flex items-center overflow-hidden'>
                                            <img className='object-cover' src={item} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            )}

        </>
    )
}

SliderExhibitor.propTypes = {}

export default SliderExhibitor
