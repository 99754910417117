import React from 'react'

import icon1 from '../assets/trend-icon.png'
import icon2 from '../assets/connect-icon.png'
import icon3 from '../assets/smarttech-icon.png'
import icon4 from '../assets/stabledevelopment-icon.png'
import icon5 from '../assets/ecosystem-icon.png'
import imgchart1 from '../assets/chart-1.jpg'

const img1 = 'https://www.hawaexpo.com/wp-content/uploads/2023/09/visitor1-3.jpg'
const img2 = 'https://www.hawaexpo.com/wp-content/uploads/2023/09/visitor2-1200x800.jpg'
const img3 = 'https://www.hawaexpo.com/wp-content/uploads/2023/03/TNK08434-1199x800.jpg'


function Visitor() {
  return (
    <div>
      <div className='h-60 bg-header'>
        <div className='h-full flex items-center' style={{ backgroundColor: "#0000005f" }}>
          <div className='w-full'>
            <h1 className='block text-4xl text-white text-center font-bold'>KHÁCH THAM QUAN</h1>
          </div>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-2 py-6 sm:px-6 lg:px-8'>
        <div className='block md:flex'>
          <div className='w-full md:w-1/2 px-4'>
            <h2 className="text-4xl font-bold tracking-tight text-color-primary">TRIỂN LÃM NỘI THẤT VÀ XÂY DỰNG VIỆT NAM</h2>
          </div>
          <div className='w-full md:w-1/2 px-4 md:px-5'>
            <p className='text-2xl'>Sẵn sàng khám phá không gian triển lãm ấn tượng với những cảm hứng sáng tạo và xu hướng dẫn đầu trong ngành nội thất và xây dựng, lần đầu tiên ra mắt tại Việt Nam.</p>
          </div>
        </div>
        <h3 className='text-2xl font-semibold text-center mt-10'>05 LÝ DO KHÔNG THỂ BỎ LỠ TRIỂN LÃM VIBE 2025</h3>
        <div className='block md:flex md:flex-wrap'>
          <div className='w-full mt-5 md:mt-0 md:w-1/3'>
            <div className='m-5 xl:m-10 border border-gray-200 p-2 overflow-hidden rounded-lg'>
              <img src={icon1} className='w-full xl:w-2/3 xl:h-52 lg:h-64 md:h-48 mx-auto object-cover' />
              <p className='py-4 text-center text-2xl font-semibold'>Khám Phá Những Xu Hướng Mới Nhất</p>
            </div>
          </div>
          <div className='w-full mt-5 md:mt-0 md:w-1/3'>
            <div className='m-5 xl:m-10 border border-gray-200 p-2 overflow-hidden rounded-lg'>
              <img src={icon2} className='w-full xl:w-2/3 xl:h-52 lg:h-64 md:h-48 mx-auto object-cover' />
              <p className='py-4 text-center text-2xl font-semibold'>Kết Nối Với Các Chuyên Gia Hàng Đầu</p>
            </div>
          </div>
          <div className='w-full mt-5 md:mt-0 md:w-1/3'>
            <div className='m-5 xl:m-10 border border-gray-200 p-2 overflow-hidden rounded-lg'>
              <img src={icon3} className='w-full xl:w-2/3 xl:h-52 lg:h-64 md:h-48 mx-auto object-cover' />
              <p className='py-4 text-center text-2xl font-semibold'>Cập Nhật Công Nghệ Thông Minh</p>
            </div>
          </div>
        </div>
        <div className='w-full md:w-2/3 mx-auto'>
          <div className='md:flex md:flex-wrap block'>
            <div className='w-full md:w-1/2'>
              <div className='m-5 xl:m-10 border border-gray-200 p-2 overflow-hidden rounded-lg'>
                <img src={icon4} className='w-full xl:w-2/3 xl:h-52 lg:h-64 md:h-48 mx-auto object-cover' />
                <p className='py-4 text-center text-2xl font-semibold'>Tập Trung Vào Phát Triển Bền Vững</p>
              </div>
            </div>
            <div className='w-full md:w-1/2'>
              <div className='m-5 xl:m-10 border border-gray-200 p-2 overflow-hidden rounded-lg'>
                <img src={icon5} className='w-full xl:w-2/3 xl:h-52 lg:h-64 md:h-48 mx-auto object-cover' />
                <p className='py-4 text-center text-2xl font-semibold'>Kết nối hệ sinh thái Nội thất - Kiến trúc - Xây dựng</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='mt-2 text-center'>
          <a target='_blank' href='/' className='bg-primary px-8 py-2 rounded-lg inline-block text-white font-bold text-xl'>ĐĂNG KÝ THAM QUAN</a>
        </div> */}
        <div className='block md:flex'>
          <div className='w-full mt-10 md:w-3/5'>
            <img src={imgchart1} className='object-cover w-full lg:w-2/3 mx-auto' />
          </div>
          <div className='w-full mt-10 md:w-2/5 px-10'>
            <h3 className='text-2xl font-semibold'>Chân Dung Các Nhà Triển Lãm</h3>
            <ul className='list-disc mt-10'>
              <li className='text-xl ml-10'>Nội thất (50%)</li>
              <li className='text-xl ml-10 mt-5'>Xây dựng (40%)</li>
              <li className='text-xl ml-10 mt-5'>Công nghệ và giải pháp thông minh (10%)</li>
            </ul>
            {/* <div className='mt-5 text-center'>
                <a target='_blank' href='/' className='bg-secondary px-8 py-2 rounded-lg inline-block text-white font-bold text-xl'>DANH SÁCH NHÀ TRIỂN LÃM</a>
              </div> */}
          </div>
        </div>

        {/* <div className='block sm:flex sm:flex-wrap items-center'>
          <div className='w-full sm:w-1/2'>
            <img src={img1} className='object-cover w-full' />
          </div>
          <div className='w-full sm:w-1/2'>
            <div className='px-10 box-border'>
              <h3 className='block text-2xl font-semibold text-gray-800'>MEET MORE & CAPTURE THE LATEST</h3>
              <div className='p-5 box-border'>
                <ul className='list-disc'>
                  <li className='mt-2 '>Meet &gt; 500 exhibitors, which is 50% new exhibitors, made-in-Vietnam manufacturers</li>
                  <li className='mt-2'>Visit more than 2.500 booths & showcases, in area of 46.000 sqm</li>
                  <li className='mt-2'>Direct to meet your demand at the customized places, located at top 2 most prestigious & biggest expo hall in Ho Chi Minh City</li>
                  <li className='mt-2'>Show a complete catalogue of local industry</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='block mt-10 sm:flex sm:flex-wrap items-center'>
          <div className='w-full sm:w-1/2'>
            <div className='px-10 box-border'>
              <h3 className='block text-2xl font-semibold text-gray-800'>1 + 1 PROGRAM (1 Exhibition + 1 Year Support Program)</h3>
              <div className='p-5 box-border'>
                <p>Via Hopefairs.com – The First Furniture Virtual Exhibition Platform in VN:</p>
                <ul className='list-disc ms-10'>
                  <li className='mt-2'>Series of business matching events</li>
                  <li className='mt-2'>Right partners, right place, great deals</li>
                </ul>
                <p>Via Vibe App – The First App Dedicated to International Exhibition in VN:</p>
                <ul className='list-disc ms-10'>
                  <li className='mt-2'>Direct online networking with exhibitors</li>
                  <li className='mt-2'>Latest industry newsletter</li>
                  <li className='mt-2'>Series of online workshops updating industrial B2B events</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='w-full sm:w-1/2'>
            <img src={img2} className='object-cover w-full' />
          </div>
        </div>
        <div className='block mt-10 sm:flex sm:flex-wrap items-center'>
          <div className='w-full sm:w-1/2'>
            <img src={img3} className='object-cover w-full' />
          </div>
          <div className='w-full sm:w-1/2'>
            <div className='px-10 box-border'>
              <h3 className='block text-2xl font-semibold text-gray-800'>GREAT VISIT EXPERIENCE</h3>
              <div className='p-5 box-border'>
                <ul className='list-disc ms-2'>
                  <li className='mt-2'>Buyer Kit (exhibition directory, badge, drink voucher, welcome dinner and souvenir)</li>
                  <li className='mt-2'>FREE registration fee</li>
                  <li className='mt-2'>Shuttle bus</li>
                  <li className='mt-2'>Hop-on-hop-off Ho Chi Minh City Tour</li>
                  <li className='mt-2'>24/7 multi-channel support</li>
                  <li className='mt-2'>Countless accommodation & travel discounts</li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className='mt-10 bg-cover bg-no-repeat' style={{
        backgroundImage: "url('https://www.hawaexpo.com/wp-content/uploads/2023/03/register-as-visitor-scaled.jpg')",
        backgroundPosition: "50% 50%", height: "425px"
      }}>
        <div className='h-full flex items-center' style={{ backgroundColor: "#0000005f" }}>
          <div className='w-full text-center'>
            <h3 className='block text-3xl alt-font text-white text-center font-bold w-2/3 mx-auto'>
              Triển lãm dẫn đầu xu hướng và phong cách tại Việt Nam trong ngành Xây Dựng & Nội thất</h3>
            {/* <a href='/' className='inline-block rounded-lg mt-5 px-7 py-3 bg-white text-color-primary hover:text-orange-500 text-xl font-semibold'>
              ĐĂNG KÝ THAM QUAN</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Visitor