import React, { useState, useEffect } from 'react'
import newsApi from '../../api/newsApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import CommonHelper from '../../helpers/common';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
const data = [
    {
        "name": "OPENING CEREMONY",
        "address": "SECC - 799 Nguyen Van Linh, Tan Phu ward, dist. 7, HCMC, Vietnam",
        "time": "09:00 AM - 10:00 AM  April, 02, 2024",
        "contact": "For more details, call Ms. Tuyet + 84 989 707 936"
    },
    {
        "name": "GALA OPENING CEREMONY",
        "address": "SECC - 799 Nguyen Van Linh, Tan Phu ward, dist. 7, HCMC, Vietnam",
        "time": "09:00 AM - 10:00 AM  April, 02, 2024",
        "contact": "For more details, call Ms. Tuyet + 84 989 707 936"
    },
    {
        "name": "THE ESSENCE OF VIETNAMESE CRAFTSMANSHIP (WORKSHOP)",
        "address": "SECC - 799 Nguyen Van Linh, Tan Phu ward, dist. 7, HCMC, Vietnam",
        "time": "09:00 AM - 10:00 AM  April, 02, 2024",
        "contact": "For more details, call Ms. Tuyet + 84 989 707 936"
    },
    {
        "name": "Hosted by JOHNY CASH (DESIGN TALK)",
        "address": "SECC - 799 Nguyen Van Linh, Tan Phu ward, dist. 7, HCMC, Vietnam",
        "time": "09:00 AM - 10:00 AM  April, 02, 2024",
        "contact": "For more details, call Ms. Tuyet + 84 989 707 936"
    },
];

function Events(props) {
    const { list, ...other } = props;
    const [state, setState] = useState([]);
    useEffect(() => {
        setState(list)
    }, [list])
    return (
        <div className='mt-10 home-news-panel'>
            {state != null && state.length > 0 && (
                <>
                    <div className='hidden md:block'>
                        <Swiper watchSlidesProgress={true} slidesPerView={3}
                            navigation={true} pagination={{
                                clickable: true,
                            }} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-md'
                                            href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <div className=''>
                                                <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <div className='flex items-center rounded-md h-60 w-full overflow-hidden'>
                                                        <img className='w-full rounded-md'
                                                            src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='px-3 mt-5 pb-3'>
                                                <a className="block text-color-primary hover:text-orange-500" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <h3 className='font-semibold text-xl pb-2'>{item.title}</h3>
                                                </a>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='md:hidden'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-md'
                                            href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <div className=''>
                                                <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <div className='flex items-center rounded-md h-36 sm:h-60 w-full overflow-hidden'>
                                                        <img className='w-full rounded-md'
                                                            src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='px-3 mt-5 pb-3'>
                                                <a className="block text-color-primary hover:text-orange-500" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                                    <h3 className='font-semibold md:text-xl pb-2'>{item.title}</h3>
                                                </a>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            )}
            {/*state != null && state.length > 0 && (
                <ul className='flex flex-wrap'>
                    {state.map((item, i) => {
                        return (
                            <li className="w-full sm:w-1/3 px-2 pb-4 mt-2 sm:mt-5" key={i}>
                                <div className='w-full '>
                                    <div className=''>
                                        <a className='block w-full' href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <div className='flex items-center rounded-md h-56 w-full overflow-hidden'>
                                                <img className='object-cover w-full rounded-md'
                                                    src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.image} title={item.title} />
                                            </div>
                                        </a>
                                    </div>
                                    <div className='px-3 mt-5 pb-3'>
                                        <a className="block text-color-primary hover:text-orange-500" href={process.env.REACT_APP_NEWS_DOMAIN_NAME + "/news/" + item.url}>
                                            <h3 className='font-semibold text-xl pb-2'>{item.title}</h3>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            )*/}
        </div>
    )
}

export default Events