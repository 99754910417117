import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faHome, faSearch } from '@fortawesome/free-solid-svg-icons'
import TextInput from '../../components/controls/text-input'
import TextAreaInput from '../../components/controls/textarea-input'
import DateInput from '../../components/controls/date-input'
import DashboardSearch from './search'
import Authentication from '../../api/authApi'
import CommonHelper from '../../helpers/common'
import Loading from '../../components/controls/loading'

function AdminHome() {
  const [state, setState] = useState({
    "SeasonId": 0, "Year": new Date().getFullYear(),
    "SeasonNameEN": "",
    "SeasonNameVN": "",
    "SloganEN": "", "SloganVN": "", "DescriptionEN": "",
    "DescriptionVN": "", "DateFrom": "2024-03-06", "DateTo": "2024-03-20",
    "Activated": "1"
  })
  const [{ dpFrom, dpTo, minDtTo, onSearch }, setSelected] = useState({
    dpFrom: new Date(),
    dpTo: new Date(),
    minDtTo: null,
    onSearch: false,
  })
  const [{ stateError, errorMsg, isLoading, isError, isSuccess, isEdit, lst_venues }, setCommonState] = useState({
    stateError: {
      //ContactName: false,
      //ContactEmail: false,
      //ContactPhone: false,
      CompanyNameEn: false,
      CompanyNameVn: false,
      AddressEn: false,
      Website: false,
      KeyProducts: false,
      VenueId: false,
      ProductTypes: false,
      BoothNo: false,
      Hall: false,
      //Password: false,
      //AccountId: false,
    },
    errorMsg: {
      CompanyNameEn: "",
      CompanyNameVn: "",
      AccountId: "",
    },
    isLoading: false,
    isError: false,
    isSuccess: false,
    isEdit: false,
    onSearch: true,
    lst_venues: [],
  })
  const handle_search = () => {
    setSelected((prev) => ({ ...prev, onSearch: true }))
  }
  const handle_change = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handle_edit = (e) => {
    setSelected((prev) => ({ ...prev, onSearch: false }))
    setState((prev) => ({
      ...prev,
      Year: e.Year,
      SeasonNameVN: e.SeasonNameVN,
      SeasonNameEN: e.SeasonNameEN,
      SloganVN: e.SloganVN,
      SloganEN: e.SloganEN,
      DescriptionVN: e.DescriptionVN,
      DescriptionEN: e.DescriptionEN,
    }))
  }
  const get_app = () => {
    setCommonState((prev) => ({ ...prev, isLoading: true }))
    const response = Authentication.get_app();
    response.then((res) => {
      setCommonState((prev) => ({ ...prev, isLoading: false }))
      if (res != null && res.data != null) {
        const e = res.data;
        setState((prev) => ({
          ...prev,
          Year: e.year,
          SeasonNameVN: e.seasonNameVN,
          SeasonNameEN: e.seasonNameEN,
          SloganVN: e.sloganVN,
          SloganEN: e.sloganEN,
          DescriptionVN: e.descriptionVN,
          DescriptionEN: e.descriptionEN,
          SeasonId: e.seasonId,
          Activated: e.activated ? "1" : "0",
          DateFrom: e.dateFrom,
          DateTo: e.dateTo,
        }))
        var dpFrom = null;
        var dpTo = null;
        if (CommonHelper.is_not_empty(e.dateFrom)) {
          dpFrom = CommonHelper.parseStringToDate(e.dateFrom, "yyyyMMdd HHmmss")
        }
        if (CommonHelper.is_not_empty(e.dateTo)) {
          dpTo = CommonHelper.parseStringToDate(e.dateTo, "yyyyMMdd HHmmss")
        }
        setSelected((prev) => ({
          ...prev, dpFrom, dpTo
        }))
      }
    })
  }
  useEffect(() => {
    get_app()
  }, [])
  const handle_save = () => {
    var data = { ...state };
    data.Activated = state.Activated == "1";
    setCommonState((prev) => ({ ...prev, isLoading: true }))
    const response = Authentication.save_exposeason(data);
    response.then((res) => {
      setCommonState((prev) => ({ ...prev, isLoading: true }))
      if (res != null) {
        if (res.code == 204) {
          setCommonState((prev) => ({
            ...prev,
            isSuccess: true,
            isError: false,
            isLoading: false,
          }));
        } else if (res.code == 506) {
          setCommonState((prev) => ({ ...prev, isLoading: true }));
          alert("Session is expired. Please sign in again!")
          const timer = setTimeout(() => {
            window.location.href = "/admin/login"
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          setCommonState((prev) => ({
            ...prev,
            isSuccess: false,
            isError: true,
            isLoading: false,
          }));
        }
      } else {
        window.location.href = "/admin/login"
      }
    })
  }
  const handle_clear = () => {
    setState((prev) => ({
      ...prev,
      Year: "",
      SeasonNameVN: "",
      SeasonNameEN: "",
      SloganVN: "",
      SloganEN: "",
      DescriptionVN: "",
      DescriptionEN: "",
      Activated: "1",
      DateFrom: "",
      DateTo: "",
    }))
    setSelected((prev) => ({
      ...prev, dpFrom: null, dpTo: null
    }))
  }
  return (
    <>
      <div className='p-3 bg-white'>
        <div className='breadscum w-full'>
          <div className='w-1/2'>
            <ul className='flex flex-wrap'>
              <li className='text-sm '>
                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
              </li>
              <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                <Link to={"/admin/dashboard"}>Dashboard </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='w-2/3'>
            <h6 className='alt-font text-color-primary font-semibold text-xl'>Dashboard</h6>
          </div>
          <div className='w-1/3 text-right'>
            <a className='cursor-pointer hidden' onClick={handle_search}>
              <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
        </div>
        <div className='mt-5'>
          <div className=' block sm:flex sm:flex-wrap'>
            <div className='w-full sm:pe-3 sm:w-1/2'>
              <label className='block required'>Season Name (VN)</label>
              <TextInput name="SeasonNameVN" value={state.SeasonNameVN} onChange={handle_change} />
            </div>
            <div className='w-full mt-2 sm:w-1/2 sm:mt-0'>
              <label className='block required'>Season Name (EN)</label>
              <TextInput name="SeasonNameEN" value={state.SeasonNameEN} onChange={handle_change} />
            </div>
            <div className='w-full sm:pe-3 mt-2 sm:w-1/6'>
              <label className='block required'>Year</label>
              <TextInput name="Year" value={state.Year} onChange={handle_change} className="text-right" />
            </div>
            <div className='w-full sm:pe-3 mt-2 sm:w-1/3'>
              <label className='block required'>Begin of date</label>
              <DateInput
                dateFormat={"dd/MM/yyyy"}
                selected={dpFrom}
                onChange={(e) => { setSelected((prev) => ({ ...prev, dpFrom: e })) }} />
            </div>
            <div className='w-full sm:pe-3 mt-2 sm:w-1/3'>
              <label className='block required'>End of date</label>
              <DateInput
                dateFormat={"dd/MM/yyyy"}
                selected={dpTo}
                onChange={(e) => { setSelected((prev) => ({ ...prev, dpTo: e })) }} />
            </div>
            <div className='w-full mt-2 sm:w-1/6'>
              <label className='block required'>Status</label>
              <select className='w-full h-8 py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none '
                value={state.Activated} onChange={handle_change}>
                <option value="1">Active</option>
                <option value="0">Disabled</option>
              </select>
            </div>
          </div>
          <div className='w-full mt-2'>
            <label className='block'>Slogan (VN)</label>
            <TextInput name="SloganVN" value={state.SloganVN} onChange={handle_change} />
          </div>
          <div className='w-full mt-2'>
            <label className='block'>Slogan (EN)</label>
            <TextInput name="SloganEN" value={state.SloganEN} onChange={handle_change} />
          </div>
          <div className='w-full mt-2'>
            <label className='block'>Description (VN)</label>
            <TextAreaInput name="DescriptionVN" className="h-16 resize-none" value={state.DescriptionVN} onChange={handle_change} />
          </div>
          <div className='w-full mt-2'>
            <label className='block'>Description (EN)</label>
            <TextAreaInput name="DescriptionEN" className="h-16 resize-none" value={state.DescriptionEN} onChange={handle_change} />
          </div>
          <div>
          </div>
        </div>
        <div className='mt-2'>
          <button type='button' onClick={handle_save}
            className='inline-flex w-32 justify-center gap-x-1.5 rounded-md bg-primary  px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-cyan-700'>Save</button>
          <button type='button' onClick={handle_clear}
            className='inline-flex w-32 justify-center gap-x-1.5 rounded-md bg-secondary  px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-orange-700 ms-2'>Clear</button>

          {isError && (
            <span className='text-red-600 py-3 block'>Update failed</span>
          )}
          {isSuccess && (
            <span className='text-green-600 py-3 block'>Update successful</span>
          )}
        </div>
      </div>
      {onSearch && (
        <div className='absolute inset-0 p-2 bg-white'>
          <DashboardSearch onEdit={handle_edit} is_show={onSearch} />
        </div>
      )}
      {isLoading && (<Loading />)}
    </>

  )
}

export default AdminHome