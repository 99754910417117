import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown, faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';

function Menu() {
    return (
        <>
            <div>
                <ul className='main-menu text-xl'>
                    <li className='float-left'><a href='/about' className=' transition  duration-700 ease-in-out'>Về Vibe</a></li>
                    <li className='float-left'><a href='/exhibitor-list' className=' transition  duration-700 ease-in-out'>Nhà triển lãm</a></li>
                    <li className='float-left'><a href='/visitor' className=' transition  duration-700 ease-in-out'>Khách tham quan</a></li>
                    <li className='float-left'><a href='/events' className=' transition  duration-700 ease-in-out'>Sự kiện</a></li>
                    <li className='float-left'><a href='/news' className=' transition  duration-700 ease-in-out'>Tin tức</a></li>
                    <li className='float-left'>
                        <a href='/floorplan' className='bg-primary rounded-3xl'>
                            <span className=' text-white'>ĐĂNG KÝ GIAN HÀNG</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Menu