import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import SliderImage1 from '../../assets/slide-1.jpg'
import SliderImage2 from '../../assets/slide-2.jpg'
import logo1 from '../../assets/logo.e68bcd67.svg'
import logo6 from '../../assets/bci-1.png'
import logo3 from '../../assets/CIO-Vietnam-logo.png'
import logo5 from '../../assets/saca_logo_CHON_1.jpg'
import logo2 from '../../assets/ELLE_Decoration-logo.png'
import logo4 from '../../assets/logo-hawa.jpg'

// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const data = [
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/AFIC-01-2-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/AHEC-2-01-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/HAMEE-01-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/HTA-01-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/ICHAM-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/SACA-01-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/2-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/VLA-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/3-1-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/d-400x400.jpg",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/6-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/5-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/03/7-400x400.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/07/kffic_logo-02-800x218.png",
    "https://www.hawaexpo.com/wp-content/uploads/2023/07/kofurn-365x400.jpg",
];

const lst_image = [logo1, logo2, logo3, logo4, logo5, logo6]

const Slider = () => {
    var group = []
    var newSlide = [];
    lst_image.map((item, i) => {
        if ((i + 1) % 3 == 0) {
            if (newSlide.length > 0) {
                group.push(newSlide);
                newSlide = [];
            }
        }
        newSlide.push(item);
    })
    console.log(group)
    if (group.length > 0) {
        return (
            <>
                {group.map((item, i) => (
                    <SwiperSlide key={i}>
                        <div className='block sm:flex sm:items-center h-36'>
                            {item.map((img, j) => (
                                <div className='block sm:w-1/3 p-3' key={j}>
                                    <div className='w-2/3 mx-auto'>
                                        <img src={img} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </SwiperSlide>
                ))}
            </>
        );
    }
    return (<></>);
}

function Partner() {
    return (
        <>
            <div>
                <Swiper
                    slidesPerView={1}
                    // autoHeight={true}
                    height={144}
                    spaceBetween={60}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={'fade'}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Keyboard, Navigation]}
                    className="mySwiper"
                >
                    {/*<Slider /> */}
                    <SwiperSlide>
                        <div className='flex items-center h-40'>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'>
                                    <img src={logo4} className='w-full object-cover' />
                                </div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'><img src={logo5} className='w-full object-cover' /></div>
                            </div>
                            {/* <div className='block sm:w-1/3 p-3'>
                                <div className='w-1/2 mx-auto'><img src={logo3} /></div>
                            </div> */}
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Partner