import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Image1 from '../../assets/furniture.png'
import Image2 from '../../assets/koda.jpg'
import Image3 from '../../assets/handicraft-2-scaled.jpg'
import Image4 from '../../assets/material-631x800.jpg'
import Image5 from '../../assets/lamps-3-b9ab4536db74d68cf308c5d3f129ce0d.jpg'
import img7 from '../../assets/interior.png'
import img8 from '../../assets/build.png'
import img9 from '../../assets/ts.png'

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';

function SliderGallery() {
    return (
        <>

            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                keyboard={{
                    enabled: true,
                }}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                navigation={true}
                modules={[Autoplay, Keyboard, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='section-box'>
                        <div className='mt-3 block sm:flex items-center'>
                            <div className='block sm:w-1/2'>
                                <div className='px-3'>
                                    <h3 className='text-center text-2xl font-semibold text-color-primary'>SẢN PHẨM TRƯNG BÀY</h3>
                                    <h3 className='text-center'><span className='fs-color-secondary font-bold text-4xl'>INTERIOR</span></h3>
                                </div>
                                <div className='block px-3 mt-10 sm:flex'>
                                    <div className='block sm:w-1/2'>
                                        <h4 className='text-color-primary text-xl font-semibold'>NỘI THẤT</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Nội thất trong nhà</li>
                                                <li className='mt-2'>Nội thất văn phòng</li>
                                                <li className='mt-2'>Đồ nội thất trẻ em</li>
                                                <li className='mt-2'>Nội thất khách sạn</li>
                                                <li className='mt-2'>Landscape</li>
                                                <li className='mt-2'>Nội thất ngoài trời</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='block sm:w-1/2'>
                                        <h4 className='text-color-primary text-xl font-semibold'>TRANG TRÍ NỘI THẤT</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Lighting</li>
                                                <li className='mt-2'>Tranh, ảnh, đồ dùng trang trí</li>
                                                <li className='mt-2'>Thiết bị Smart HOME</li>
                                                <li className='mt-2'>Sản phẩm về mùi hương</li>
                                                <li className='mt-2'>Interior fixtures &amp; fitting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='block px-3 mt-10 sm:flex'>
                                    <div className='block sm:w-1/2'>
                                        <h4 className='text-color-primary text-xl font-semibold mt-10'>VẬT LIỆU NỘI THẤT</h4>
                                        <div className='ps-5 my-3 lg:mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Thảm, rèm</li>
                                                <li className='mt-2'>Sơn mỹ thuật</li>
                                                <li className='mt-2'>Linh kiện, phụ kiện</li>
                                                <li className='mt-2'>Sàn gỗ, giấy dán tường…</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='block sm:w-1/2'>
                                        <h4 className='text-color-primary text-xl font-semibold mt-10'>THIẾT BỊ NỘI THẤT</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Thiết bị, phụ kiện nhà bếp </li>
                                                <li className='mt-2'>Thiết bị, phụ kiện phòng tắm </li>
                                                <li className='mt-2'>Thiết bị Smart HOME</li>
                                                <li className='mt-2'>Thiết bị sân vườn &amp; hồ bơi </li>
                                                <li className='mt-2'>Thiết bị điện tử</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='block sm:w-1/2'>
                                <img src={img7} className='object-cover w-full' />
                            </div>
                            {/* <SliderGallery /> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='section-box mt-10'>
                        <div className='mt-3 block sm:flex items-center'>
                            <div className='block sm:w-1/2'>
                                <div className='px-3'>
                                    <h3 className='text-center text-2xl font-semibold text-color-primary'>SẢN PHẨM TRƯNG BÀY</h3>
                                    <h3 className='text-center'><span className='fs-color-secondary font-bold text-4xl'>BUILD & CONSTRUCTION</span></h3>
                                </div>
                                <div className='block sm:flex'>
                                    <div className='block px-3'>
                                        <h4 className='text-color-primary text-xl font-semibold mt-10'>VẬT LIỆU XD THÔ</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Xi măng</li>
                                                <li className='mt-2'>Sắt thép</li>
                                                <li className='mt-2'>Gạch xây dựng</li>
                                            </ul>
                                        </div>
                                        <h4 className='text-color-primary text-xl font-semibold mt-10'>NHÓM KIẾN TRÚC/XÂY DỰNG/ BDS</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Nhà thầu xây dựng</li>
                                                <li className='mt-2'>Dự án bất động sản phong cách</li>
                                                <li className='mt-2'>Văn phòng kiến trúc</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='mt-10 px-3'>
                                        <h4 className='text-color-primary text-xl font-semibold'>VẬT LIỆU XD HOÀN THIỆN</h4>
                                        <div className='ps-5 mt-3 text-left'>
                                            <ul className='font-semibold list-disc'>
                                                <li className='mt-2'>Kính, cửa, cửa sổ</li>
                                                <li className='mt-2'>Gạch lát nền, đá ốp lát</li>
                                                <li className='mt-2'>Vật liệu mái, tường, trần, sàn,</li>
                                                <li className='mt-2'>Sơn nước</li>
                                                <li className='mt-2'>Vật tư ngoại thất: Thép, sắt, gạch, gỗ, vữa, nhôm</li>
                                                <li className='mt-2'>Thiết bị vệ sinh, thiết bị phòng tắm</li>
                                                <li className='mt-2'>Thiết bị ngoài trời và hồ bơi</li>
                                                <li className='mt-2'>Ống và phụ tùng, dây điện</li>
                                                <li className='mt-2'>Hóa chất, phụ gia</li>
                                                <li className='mt-2'>Công cụ, dụng cụ</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='block sm:w-1/2'>
                                <img src={img8} className='object-cover w-full' />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='section-box mt-10'>
                        <div className='mt-3 block sm:flex sm:items-center'>
                            <div className='block sm:w-1/2'>
                                <div className='px-3'>
                                    <h3 className='text-center text-2xl font-semibold text-color-primary'>SẢN PHẨM TRƯNG BÀY</h3>
                                    <h3 className='text-center'><span className='fs-color-secondary font-bold text-4xl'>TECHNOLOGY &SERVICE</span></h3>
                                </div>
                                <div className='mt-5 px-3 mb-5 lg:mb-0'>
                                    {/* <h4 className='fs-color-secondary text-xl font-semibold'>VẬT LIỆU XD HOÀN THIỆN</h4> */}
                                    <div className='ps-5 mt-3 text-left'>
                                        <ul className='font-semibold list-disc'>
                                            <li className='mt-2'>Hệ thống &amp; công nghệ xây dựng công nghiệp hóa </li>
                                            <li className='mt-2'>Giải pháp và phần mềm công nghệ cao</li>
                                            <li className='mt-2'>BIM/CIM</li>
                                            <li className='mt-2'>Dịch vụ chứng chỉ chuyển đổi số ngành bán lẻ</li>
                                            <li className='mt-2'>Hệ thống an ninh</li>
                                            <li className='mt-2'>Nhà thông minh, Nhà máy thông minh</li>
                                            <li className='mt-2'>Dịch vụ chứng chỉ &amp; hệ thống tự động hóa</li>
                                            <li className='mt-2'>Nền tảng thương mại điện tử (E-Commerce Platform)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='block sm:w-1/2'>
                                <img src={img9} className='object-cover w-full' />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            {/* <div>
                <div className='flex text-color-primary'>
                    <div className='flex-0 w-2/3'>
                        <div className='flex flex-col'>
                            <div className='flex'>
                                <div className='flex-0 w-1/3 mr-2'>
                                    <div className='h-60'>
                                        <img src={Image1} className='object-cover h-full' />
                                    </div>
                                    <div className='gal-title py-2 px-7 bg-slate-200 relative'>
                                        <span className='block'>Furniture & Furnishing</span>
                                    </div>
                                </div>
                                <div className='flex-0 w-2/3 mr-2'>
                                    <div className='h-60  overflow-hidden'>
                                        <img src={Image2} className='object-cover w-full' /></div>
                                    <div className='gal-title green py-2 px-7 bg-slate-200 relative'>
                                        Interior Design & Contract Furniture
                                    </div>
                                </div>
                            </div>
                            <div className='flex mt-2'>
                                <div className='flex-1 mr-2'>
                                    <div className='h-60'>
                                        <img src={Image3} className='object-cover w-full' /></div>
                                    <div className='gal-title blue py-2 px-7 bg-slate-200 relative'>
                                        Gift & Handicraft
                                    </div>
                                </div>
                                <div className='flex-1 mr-2'>
                                    <div className='h-60 overflow-hidden'>
                                        <img src={Image4} className='object-cover w-full' /></div>
                                    <div className='gal-title blue py-2 px-7 bg-slate-200 relative'>
                                        Material & Hardware
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-0 w-1/2'>
                        <div className='h-full -mb-10'>
                            <img src={Image5} className='object-cover h-full' /></div>
                        <div className='gal-title  yellow py-2 px-7 bg-slate-200 relative'>
                            Home Accessories
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default SliderGallery