import React from 'react'
import vi from 'date-fns/locale/vi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//registerLocale('vi', vi);
//ref: https://github.com/pushtell/react-bootstrap-date-picker#readme

function DateInput(props) {
    return (
        <ReactDatePicker
            {...props}
            shouldCloseOnSelect
            isClearable
            showPopperArrow={false}
            timeCaption='Hours'
            locale="vi"
            dateFormat={props.dateFormat === undefined ? "dd/MM/yyyy HH:mm:00" : props.dateFormat}
            //showMonthDropdown={true}
            dropdownMode="select"
            className={'w-full py-1 px-3 rounded border-gray-400 border hover:border-cyan-900 active:border-cyan-900 focus:border-cyan-900 focus:outline-none ' + props.className} />
    )
}

export default DateInput