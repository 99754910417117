import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import SliderImage1 from '../../assets/slide-1.jpg'
import SliderImage2 from '../../assets/slide-2.jpg'
import logo1 from '../../assets/logo.e68bcd67.svg'
import logo6 from '../../assets/bci-1.png'
import logo3 from '../../assets/CIO-Vietnam-logo.png'
import logo5 from '../../assets/saca_logo_CHON_1.jpg'
import logo2 from '../../assets/ELLE_Decoration-logo.png'
import logo4 from '../../assets/logo-hawa.jpg'
// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const lst_image = [logo1, logo2, logo3, logo4, logo5, logo6]

function EndorsedBy() {
    return (
        <>
            <div>
                <Swiper
                    slidesPerView={1}
                    // autoHeight={true}
                    height={144}
                    spaceBetween={60}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={'fade'}
                    autoplay={{
                        delay: 35000,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Keyboard, Navigation]}
                    className="mySwiper"
                >
                    {/*<Slider /> */}
                    <SwiperSlide>
                        <div className='flex items-center h-36'>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 mx-auto'>
                                    <img src={logo1} />
                                </div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 mx-auto'><img src={logo2} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex sm:items-center h-36'>
                            {/* <div className='block sm:w-1/3 p-3'>
                                <div className='w-1/2 mx-auto'><img src={logo4} /></div>
                            </div>
                            <div className='block sm:w-1/3 p-3'>
                                <div className='w-1/2 mx-auto'><img src={logo5} /></div>
                            </div> */}
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 mx-auto'><img src={logo3} /></div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 mx-auto'><img src={logo6} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            {/* <div className='flex flex-wrap sm:flex-nowrap'>
                <div className='w-full sm:w-1/3 py-5'>
                    <div className='mx-auto w-24 h-24 overflow-hidden'>
                        <img src='https://www.hawaexpo.com/wp-content/uploads/2022/10/logo1-400x400-1.png' />
                    </div>
                    <p className='mt-4 text-center px-2'>THE MINISTRY OF AGRICULTURAL AND RURAL DEVELOPMENT</p>
                </div>
                <div className='w-full sm:w-1/3 py-5'>
                    <div className='mx-auto w-24 h-24 overflow-hidden'>
                        <img src='https://www.hawaexpo.com/wp-content/uploads/2022/10/Quoc-Huy-Viet-Nam-01-395x400.png' />
                    </div>
                    <p className='mt-4 text-center px-2'>THE MINISTRY OF INDUSTRY AND TRADE</p>
                </div>
                <div className='w-full sm:w-1/3 py-5'>
                    <div className='mx-auto w-24 h-24 overflow-hidden'>
                        <img src='https://www.hawaexpo.com/wp-content/uploads/2022/10/unnamed-400x400.png' />
                    </div>
                    <p className='mt-4 text-center px-2'>HCMC EXPO - HCMC DEPARTMENT OF INDUSTRY & TRADE</p>
                </div>
            </div> */}
        </>
    )
}

export default EndorsedBy