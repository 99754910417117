import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux'
import CommonHelper from '../../../helpers/common'
import { organizerActions } from '../../../_store/organizer.slice'
import { faBan, faCheck, faHome, faMinus, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectUnit from '../../../components/controls/select-unit';
import InputTextUnit from '../../../components/controls/input-text-unit';
import visitorApi from '../../../api/visitorApi';

function AdminVisitorSearch(props) {
    const dispatch = useDispatch();
    const { onEdit, ...other } = props;
    const [state, setState] = useState({
        season_id: 0,
        company_name: "",
        contact_name: "",
        vip: "2",
        visitor_type: "2",
        is_loading: false,
        page_no: 1,
        page_size: 10,
    });
    const [{ lst_data, lst_vip, lst_visitor_type }, setList] = useState({
        lst_data: [],
        lst_vip: [{ "code": "2", "name": "ALL" }, { "code": "1", "name": "Yes" }, { "code": "0", "name": "No" },],
        lst_visitor_type: [{ "code": "2", "name": "ALL" }, { "code": "1", "name": "Individual" }, { "code": "0", "name": "Organization" },],
    });
    const handle_edit = (e) => {
        onEdit(e);
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    }
    const handle_submit = () => {
        var data = { ...state };
        console.log(data)
        dispatch(organizerActions.set_loading())
        const response = visitorApi.get_visitors(data);
        response.then((res) => {
            var lst_data = [];
            if (res != null) {
                if (res.code == 200) {
                    lst_data = res.data;
                } else if (res.code == 506) {
                    alert("Session is expired. Please sign in again!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                }
            }
            setList((prev) => ({ ...prev, lst_data }));
            dispatch(organizerActions.set_loading())
        })
    }
    const handle_export = () => {
        var data = { ...state };
        console.log(data)
        dispatch(organizerActions.set_loading())
        const response = visitorApi.get_visitors_file(data);
        response.then((res) => {
            console.log(res);
            /*var lst_data = [];
            if (res != null) {
                if (res.code == 200) {
                    lst_data = res.data;
                } else if (res.code == 506) {
                    alert("Session is expired. Please sign in again!")
                    const timer = setTimeout(() => {
                        window.location.href = "/admin/login"
                    }, 4000);
                }
            }
            setList((prev) => ({ ...prev, lst_data }));*/
            dispatch(organizerActions.set_loading())
        })
    }
    return (
        <>
            <div className='p-3 bg-white'>
                <div className='breadscum w-full flex'>
                    <div className='w-1/2'>
                        <ul className='flex flex-wrap'>
                            <li className='text-sm '>
                                <Link to={"/"}><FontAwesomeIcon icon={faHome} /> </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link to={"/admin/visitor"}>Visitor </Link>
                            </li>
                            <li className='text-sm pl-2 leading-normal before:float-left before:pr-2 before:content-["/"]'>
                                <Link >Search </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 text-right'>
                        <a onClick={() => { handle_edit(null) }} className='cursor-pointer'>
                            <FontAwesomeIcon icon={faTimes} />
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='w-full block sm:flex'>
                        <div className='w-full mt-2 px-2 sm:pe-2 sm:w-1/4'>
                            <SelectUnit onChange={handle_change} value={state.vip} name='vip'
                                label='VIP' required={false} isError={false} options={lst_vip} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:w-1/4'>
                            <SelectUnit onChange={handle_change} value={state.visitor_type} name='visitor_type'
                                label='Individual/ Organization' required={false} isError={false} options={lst_visitor_type} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:w-1/4'>
                            <InputTextUnit onChange={handle_change} value={state.company_name} name='company_name'
                                label='Company Name' required={false} isError={false} />
                        </div>
                        <div className='w-full mt-2 px-2 sm:ps-2  sm:w-1/4'>
                            <InputTextUnit onChange={handle_change} value={state.contact_name} name='contact_name'
                                label='Contact Name' required={false} isError={false} />
                        </div>
                    </div>
                    <div className='w-full'>
                        <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 box-border text-lg bg-orange-500'
                            onClick={handle_submit}>
                            Search</button>
                        <button type='button' className='mt-5 w-32 rounded-2xl text-white py-1 px-2 ml-5 box-border text-lg bg-green-600'
                            onClick={handle_export}>
                            Excel</button>
                    </div>
                </div>
                <div className='mt-3'>
                    <table className='border-collapse border border-slate-400 w-full'>
                        <thead>
                            <tr>
                                <th className='border border-slate-300 w-14 ps-2 text-center'>#</th>
                                <th className='border border-slate-300 w-1/4 ps-2 text-center'>Company Name</th>
                                <th className='border border-slate-300 w-1/4 ps-2 text-center'>Contact Name</th>
                                <th className='border border-slate-300 w-1/5 px-2 text-center'>Individual/ Organization</th>
                                <th className='border border-slate-300 w-14 px-2 text-center'>VIP</th>
                                <th className='border border-slate-300 w-14 px-2 text-center'>Status</th>
                                <th className='border border-slate-300'></th>
                            </tr>
                        </thead>
                        {lst_data != null && lst_data.length > 0 && (
                            <tbody>
                                {lst_data.map((item, i) => (
                                    <tr key={i}>
                                        <td className='border border-slate-300 text-right pr-2'>{i + 1}</td>
                                        <td className='border border-slate-300 px-2'>{item.companyNameEn}</td>
                                        <td className='border border-slate-300 px-2'>{item.contactName}</td>
                                        <td className='border border-slate-300 px-2'>{item.individual ? "Individual" : "Organization"}</td>
                                        <td className='border border-slate-300 text-center'>{item.vip && (<FontAwesomeIcon icon={faCheck} className='text-green-500' />)}{!item.vip && (<FontAwesomeIcon icon={faMinus} />)}</td>
                                        <td className='border border-slate-300 text-center'>{item.activated && (<FontAwesomeIcon icon={faCheck} className='text-green-500' />)}{!item.activated && (<FontAwesomeIcon icon={faBan} className='text-red-600' />)}</td>
                                        <td className='border border-slate-300'>
                                            <a className='block flex-auto cursor-pointer text-center' onClick={() => { handle_edit(item) }}>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    )
}

AdminVisitorSearch.propTypes = {
    onEdit: PropTypes.func.isRequired,
}

export default AdminVisitorSearch
