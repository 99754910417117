import './App.css';
import { useState, useEffect, useLayoutEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faEnvelope, faLocationDot, faMapMarker, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock, faHandPointer } from '@fortawesome/free-regular-svg-icons';
import Authentication from './api/authApi'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import logo from './assets/logo-vibe.svg'
import logo_desc from './assets/vibeep.png'
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Menu from './components/menu';
import MobileMenu from './components/mobile-menu';
import Footer from './components/footer';
import CommonHelper from './helpers/common';
import Account from './components/account';
import { UseSelector, useDispatch } from 'react-redux';
import { authActions } from './_store';
function App() {
  const dispatch = useDispatch();
  const [{ sticky, isAuth }, setExtension] = useState({
    sticky: "",
  })
  useLayoutEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset >= 215) {
        setExtension((prev) => ({
          ...prev,
          sticky: "sticky-active"
        }))
      } else {
        setExtension((prev) => ({
          ...prev,
          sticky: ""
        }))
      }
    })
  }, [])
  useEffect(() => {
    dispatch(authActions.check_token());
    //Authentication.check_token_expired();
    //Authentication.get_app();
    var get_user = CommonHelper.get_user();
    get_user.then((data) => {
      setExtension((prev) => ({
        ...prev,
        isAuth: data != null
      }))
    })
  }, [])
  const [{ menu }, setClick] = useState({
    menu: false
  })
  const handle_logout = () => {
    var response = Authentication.exhibitor_logout();
    response.then((res) => {
      if (res != null && res.code == 506) {
        window.location.href = "/"
      }
    })
  }
  return (
    <>
      <div className='min-h-full'>
        <nav className="shadow-lg">
          <div>
            <div className='bg-primary hidden md:block'>
              <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='flex h-8 text-white'>
                  <div className='w-3/4 flex items-center font-semibold' style={{ fontSize: "13px" }}>
                    <ul className='list-none top-location'>
                      <li className='float-left mr-4'>
                        <a href='/'>
                          <FontAwesomeIcon icon={faLocationDot} /> Vietnam Interior & Build Exhibition
                        </a>
                      </li>
                      <li className='float-left ps-4'>
                        <a href='/'>
                          <FontAwesomeIcon icon={faClock} /> 02-05/10/2024
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-1/4 items-center flex'>
                    <div className='w-full  text-right'>
                      <a className='social-link mr-2' href='https://www.facebook.com/Vibexpo'>
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                      <a className='social-link mr-2' href='https://www.instagram.com/vibe_expo_vietnam/'>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a className='social-link mr-2' href='https://www.linkedin.com/company/vibe-vietnam-interior-build-expo/'>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <a className='social-link' href='/'>
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id='header-top' className={'w-full bg-white ' + sticky}>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between">
                  <div className="block w-1/6 lg:hidden">
                    <button type="button" className="relative inline-flex items-center justify-center text-2xl rounded-md" aria-controls="mobile-menu" aria-expanded="false"
                      onClick={() => { setClick((prev) => ({ ...prev, menu: !menu })) }}>
                      <FontAwesomeIcon icon={faBars} className='text-gray-600' />
                    </button>
                  </div>
                  <div className="block w-2/3 sm:flex items-center sm:w-full">
                    <div className="w-full lg:w-1/5">
                      <a href='/' className='block w-full text-center lg:text-left'>
                        <img src={logo_desc} alt="VIBE" className='w-32 md:w-44 inline-block' />
                      </a>
                    </div>
                    <div className="hidden lg:block lg:w-full">
                      <div className='float-right'>
                        <Menu />
                      </div>
                    </div>
                    <div className='hidden lg:block lg:w-1/5'>
                      <Account isAuthenticated={isAuth} onLogout={handle_logout} />
                    </div>
                  </div>
                  <div className='block w-1/6 lg:hidden'></div>
                </div>
              </div>
            </div>
          </div>
          {menu && (<MobileMenu onClose={() => { setClick((prev) => ({ ...prev, menu: false })) }} />)}
        </nav>
        <main>
          <Outlet />
        </main>
        <footer>
          <Footer />
        </footer>
        <div className="hotline-phone-ring-wrap">
          <div className="hotline-phone-ring">
            <div className="hotline-phone-ring-circle"></div>
            <div className="hotline-phone-ring-circle-fill"></div>
            <div className="hotline-phone-ring-img-circle">
              <a href="tel:0966965747" className="pps-btn-img">
                {/* <img src="http://thevibexpo.com/wp-content/plugins/hotline-phone-ring/assets/images/icon-2.png" alt="Hotline" width="50" /> */}
                <FontAwesomeIcon icon={faPhone} className='text-2xl text-white' />
              </a>
            </div>
          </div>
          <div className="hotline-bar">
            <a href="tel:0966965747">
              <span className="text-hotline">0966965747</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
