import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import img2 from '../assets/core-value.jpg'
import img1 from '../assets/house.jpg'
import img3 from '../assets/other-value-1.jpg'
import img4 from '../assets/other-value-2.jpg'
import img5 from '../assets/other-value-3.jpg'
import img6 from '../assets/other-value-4.jpg'
import img7 from '../assets/interior.png'
import img8 from '../assets/build.png'
import img9 from '../assets/ts.png'
import img10 from '../assets/p1.png'
import img11 from '../assets/p2.png'
import img12 from '../assets/p3.png'
import imgchart1 from '../assets/chart-1.jpg'
import imgchart2 from '../assets/chart-2.jpg'
import iconTick from '../assets/icon-tick.svg'
import imgHome1 from '../assets/home-1.jpg'
import imgHome2 from '../assets/home-2.jpg'
import imgHome3 from '../assets/home-3.jpg'
import imgHome4 from '../assets/home-4.jpg'
import imgHome5 from '../assets/home-5.jpg'
import imgHome6 from '../assets/home-6.jpg'
import imgHome7 from '../assets/home-7.jpg'
import imgHome8 from '../assets/home-8.jpg'
import imgHome9 from '../assets/home-9.jpg'
import imgHome10 from '../assets/home-10.jpg'
import imgHome11 from '../assets/home-11.jpg'
import imgHome12 from '../assets/home-12.jpg'
import imgSponsor1 from '../assets/sponsor-1.jpg'
import imgSponsor2 from '../assets/sponsor-2.jpg'
import imgSponsor3 from '../assets/sponsor-3.jpg'
import imgSponsor4 from '../assets/sponsor-4.jpg'
import imgInterio1 from '../assets/interior-1.jpg'
import logoHawa from '../assets/logo-hawa.jpg'
import logoSaca from '../assets/saca_logo_CHON_1.jpg'
import logoVnia from '../assets/logo-vnia.jpg'

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import CountDownTime from './count-down-time';
import SliderBanner from './slider-banner';
import FeaturedExhibitors from './featured-exhibitors';
import SliderGallery from './slider-gallery';
import Events from './events';
import Testimonials from './testimonial';
import EndorsedBy from './endorsed-by';
import Organizer from './organizers';
import Partner from './partner';
import AdsPopup from '../components/controls/ads-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import exhibitorApi from '../api/exhibitorApi';
import newsApi from '../api/newsApi';
import SliderExhibitor from './slider-exhibitor';


function Home() {
    const [{ ListFeaturedExhibitors, ListNews }, setCommonState] = useState({
        ListFeaturedExhibitors: [],
        ListNews: [],
    })
    const [{ show_ads }, setShow] = useState({
        show_ads: false
    })
    /*const get_banner = () => {
        const response = onlineApi.get_banner();
        response.then((res) => {
            console.log(res);
        })
    }*/
    useEffect(() => {
        get_featured_exhibitor();
        get_news();
    }, [])
    const get_featured_exhibitor = () => {
        const response = exhibitorApi.get_exhibitor_in_home()
        response.then((res) => {
            if (res != null && res.code == 200) {
                setCommonState((prev) => ({ ...prev, ListFeaturedExhibitors: res.data }));
            }
        })
    }
    const get_news = () => {
        const response = newsApi.get_news_in_home();
        response.then((res) => {
            console.log(res)
            if (res != null && res.code == 200) {
                var ListNews = res.data
                setCommonState((prev) => ({ ...prev, ListNews }));
            }
        })
    }
    return (
        <>
            <div className='bg-backdrop relative'>
                <div className='h-full bg-overlay absolute inset-0'></div>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 relative' style={{ height: "500px" }}>
                    <div className='px-2 sm:px-0 md:mt-10 block sm:flex'>
                        <div className='px-5 py-5' style={{ background: "#36363694" }}>
                            <h1 className='text-3xl sm:text-5xl font-bold fs-color-secondary'>TRIỂN LÃM NỘI THẤT VÀ XÂY DỰNG VIỆT NAM - VIBE 2024</h1>
                            <h2 className='text-2xl sm:text-2xl font-bold text-white mt-8'><FontAwesomeIcon icon={faClock} /> 02-05/10/2024</h2>
                            <h2 className='text-2xl sm:text-2xl font-bold text-white mt-3'><FontAwesomeIcon icon={faLocationDot} /> SECC - 799 Nguyễn Văn Linh, P. Tân Phú, Quận 7, TP. HCM</h2>
                            <div className='mt-8'>
                                <a target='_blank' href='/floorplan' className='bg-primary px-8 py-2 rounded-lg inline-block text-white font-bold text-xl'>ĐĂNG KÝ GIAN HÀNG</a>
                                <a href='/register' className='bg-white mt-5 sm:mt-0 sm:ml-5 px-8 py-2 rounded-lg inline-block text-color-primary font-bold text-xl'>ĐĂNG KÝ THAM QUAN</a>
                            </div>
                        </div>
                    </div>
                    <div className='block mx-auto w-full md:w-1/2 md:absolute bottom-5 xl:-ml-80 lg:-ml-52 md:-ml-52 md:left-1/2'>
                        <CountDownTime />
                    </div>
                </div>
            </div>

            <div className='bg-backdrop2 mt-10'>
                <div className='mx-auto max-w-7xl px-2 py-6 sm:px-6 lg:px-8'>
                    <div className='block lg:flex bg-primary rounded-md'>
                        <div className='w-full block sm:flex xl:w-2/5 lg:w-1/2'>
                            <div className='w-full sm:w-1/3 p-2'>
                                <div className='rounded-md lg:h-36 overflow-hidden'>
                                    <img src={imgHome1} className='w-full lg:h-full bject-cover' />
                                </div>
                            </div>
                            <div className='w-full sm:w-1/3 p-2'>
                                <div className='rounded-md lg:h-36 overflow-hidden'>
                                    <img src={imgHome2} className='w-full lg:h-full bject-cover' />
                                </div>
                            </div>
                            <div className='w-full sm:w-1/3 p-2'>
                                <div className='rounded-md lg:h-36 overflow-hidden'>
                                    <img src={imgHome3} className='w-full lg:h-full bject-cover' />
                                </div>
                            </div>
                        </div>
                        <div className='w-full xl:w-3/5 lg:w-1/2 px-10 py-5 box-border'>
                            <p className='leading-normal text-xl text-white'><span className='fs-color-secondary font-semibold'>VIBE</span> mang đến một <span className='fs-color-secondary font-semibold'>Nền tảng kết nối & lan tỏa</span> những giá trị và cơ hội trong chuỗi cung ứng ngành <span className='fs-color-secondary font-semibold'>Nội thất & Xây dựng</span> đáp ứng các tiêu chí <span className='fs-color-secondary'>Style</span> (Phong cách) – <span className='fs-color-secondary font-semibold'>Smart</span> (Công nghệ) - <span className='fs-color-secondary font-semibold'>Sustainability</span> (Bền vững) dành cho thị trường nội địa và dự án.</p>
                        </div>
                    </div>
                    <div className='my-10 py-10 block bg-white'>
                        <h3 className='text-center text-2xl sm:text-4xl font-semibold text-color-primary'>Sứ mệnh & Mục tiêu</h3>
                        <div className='block lg:flex lg:items-center'>
                            <div className='w-full mt-10 lg:w-1/3 px-10'>
                                <div className='bg-secondary h-28 text-center px-5 flex items-center font-semibold text-2xl text-white'>Cung cấp thông tin & xu hướng ngành</div>
                                <div className='w-full h-80 overflow-hidden'>
                                    <img src={imgSponsor2} className='w-full object-cover' />
                                </div>
                            </div>
                            <div className='w-full mt-10 lg:w-1/3 px-10'>
                                <div className='w-full h-80 overflow-hidden'>
                                    <img src={imgSponsor3} className='w-full object-cover' />
                                </div>
                                <div className='bg-primary h-28 text-center px-5 flex items-center font-semibold text-2xl text-white'>Mở rộng kết nối chuỗi cung ứng Ngành Nội thất & Xây dựng</div>
                            </div>
                            <div className='w-full mt-10 lg:w-1/3 px-10'>
                                <div className='bg-secondary h-28 text-center px-5 flex items-center font-semibold text-2xl text-white'>Tìm kiếm nguồn hàng Thúc đẩy kinh doanh</div>
                                <div className='w-full h-80 overflow-hidden'>
                                    <img src={imgSponsor4} className='w-full object-cover' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*FEATURED EXHIBITORS */}
            <div className='hidden mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide">TIÊU CHÍ CỐT LÕI <span className='fs-color-secondary'>VIBE</span></h2>
                    <div className='mt-3'>
                        <img src={img2} className='object-cover' />
                    </div>
                </div>
            </div>

            <div className='mt-10 hidden'>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                    <div className='section-box'>
                        <div className='block sm:flex'>
                            <div className='block sm:w-full text-center'>
                                <h2 className="text-2xl sm:text-4xl font-bold text-color-primary tracking-wide">TẠI SAO NÊN THAM GIA TRIỂN LÃM TẠI VIBE?</h2>
                            </div>
                            {/* <div className='block sm:w-3/5'>
                                <p className='text-xl font-semibold sm:text-xl sm:ps-3'>Hội chợ cung cấp toàn diện giải pháp về Vật liệu – Xây dựng – Nội thất đáp ứng các tiêu chí Style– Smart – Sustainability dành cho thị trường nội địa và xuất khẩu dự án.</p>
                            </div> */}
                        </div>
                        <div className='block sm:flex sm:flex-wrap item-center align-middle my-10'>
                            <div className='block sm:w-1/5 mt-5 sm:mt-0'>
                                <p className='text-center font-semibold p-3 xl:h-24 md:h-36'>
                                    Quy hoạch bài bản & tuyển chọn doanh nghiệp theo lĩnh vực, ngành nghề
                                </p>
                                <div className='md:h-36 px-3 overflow-hidden'>
                                    <img src={img5} className='object-cover w-full rounded-lg' />
                                </div>
                            </div>
                            <div className='block sm:w-1/5 mt-5 sm:mt-0'>
                                <p className='text-center font-semibold p-3 xl:h-24 md:h-36'>
                                    Kết nối hệ sinh thái trong lĩnh vực xây dựng và nội thất B2B – B2C
                                </p>
                                <div className='md:h-36 px-3 overflow-hidden'>
                                    <img src={img1} className='object-cover w-full rounded-lg' />
                                </div>
                            </div>
                            <div className='block sm:w-1/5 mt-5 sm:mt-0'>
                                <p className='text-center font-semibold p-3 xl:h-24 md:h-36'>
                                    Dẫn dắt về xu hướng, định hướng cho nhà sản xuất và người tiêu dùng
                                </p>
                                <div className='md:h-36 px-3 overflow-hidden'>
                                    <img src={img6} className='object-cover w-full rounded-lg' />
                                </div>
                            </div>
                            <div className='block sm:w-1/5 mt-5 sm:mt-0'>
                                <p className='text-center font-semibold p-3 xl:h-24 md:h-36'>
                                    Chiến lược truyền thông bàn bản, tác động đúng đối tượng, ngành nghề
                                </p>
                                <div className='md:h-36 px-3 overflow-hidden'>
                                    <img src={img3} className='object-cover w-full rounded-lg' />
                                </div>
                            </div>
                            <div className='block sm:w-1/5 mt-5 sm:mt-0'>
                                <p className='text-center font-semibold p-3 xl:h-24 md:h-36'>
                                    Ban tổ chức giàu kinh nghiệm,  tính  chuyên môn cao
                                </p>
                                <div className='md:h-36 px-3 overflow-hidden'>
                                    <img src={img4} className='object-cover w-full rounded-lg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='hidden'>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                    <div className='section-box'>
                        <div className='block sm:flex'>
                            <div className='block sm:w-full text-center'>
                                <h2 className="text-2xl sm:text-4xl font-bold text-color-primary tracking-wide">BAN GIÁM TUYỂN/ CỐ VẤN CHUYÊN MÔN CỦA VIBE</h2>
                            </div>
                        </div>
                        <div className='block sm:flex sm:flex-wrap item-center align-middle my-10'>
                            <div className='block sm:w-1/5'>
                                <div className='h-36 px-3 overflow-hidden'>
                                    <img src={img5} className='object-cover w-full' />
                                </div>
                                <div className='px-3'>
                                    <h5 className='text-xl font-semibold my-5'>Thùy Dương Nguyễn</h5>
                                    <p className=''>
                                        Tổng biên tập Elle Decoration Việt Nam
                                    </p>
                                </div>
                            </div>
                            <div className='block sm:w-1/5'>
                                <div className='h-36 px-3 overflow-hidden'>
                                    <img src={img1} className='object-cover w-full' />
                                </div>
                                <div className='px-3'>
                                    <h5 className='text-xl font-semibold my-5'>Thùy Dương Nguyễn</h5>
                                    <p className=''>
                                        Tổng biên tập Elle Decoration Việt Nam
                                    </p>
                                </div>
                            </div>
                            <div className='block sm:w-1/5'>
                                <div className='h-36 px-3 overflow-hidden'>
                                    <img src={img6} className='object-cover w-full' />
                                </div>
                                <div className='px-3'>
                                    <h5 className='text-xl font-semibold my-5'>Thùy Dương Nguyễn</h5>
                                    <p className=''>
                                        Tổng biên tập Elle Decoration Việt Nam
                                    </p>
                                </div>
                            </div>
                            <div className='block sm:w-1/5'>
                                <div className='h-36 px-3 overflow-hidden'>
                                    <img src={img3} className='object-cover w-full' />
                                </div>
                                <div className='px-3'>
                                    <h5 className='text-xl font-semibold my-5'>Thùy Dương Nguyễn</h5>
                                    <p className=''>
                                        Tổng biên tập Elle Decoration Việt Nam
                                    </p>
                                </div>
                            </div>
                            <div className='block sm:w-1/5'>
                                <div className='h-36 px-3 overflow-hidden'>
                                    <img src={img4} className='object-cover w-full' />
                                </div>
                                <div className='px-3'>
                                    <h5 className='text-xl font-semibold my-5'>Thùy Dương Nguyễn</h5>
                                    <p className=''>
                                        Tổng biên tập Elle Decoration Việt Nam
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='relative w-full text-center mt-5'>
                        <a target='_blank' href='/floorplan' className='bg-primary px-8 py-2 rounded-lg inline-block text-white font-bold text-xl'>ĐĂNG KÝ GIAN HÀNG</a>
                    </div>
                </div>
            </div>

            {/* NHUNG CON SO KY VONG */}
            <div className='bg-backdrop2 mt-10' style={{ backgroundPosition: "50% 50%", minHeight: "500px" }}>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                    <div className='section-box'>
                        <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">NHỮNG CON SỐ KỲ VỌNG TẠI VIBE 2024</h2>
                        <div className='mt-5 block sm:flex'>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-secondary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>650</h2>
                                    <span className='block text-xl font-semibold mt-4'>Gian hàng</span>
                                </div>
                            </div>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-secondary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>12,000</h2>
                                    <span className='block text-xl font-semibold mt-4'>Khách tham quan</span>
                                </div>
                            </div>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-secondary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>250</h2>
                                    <span className='block text-xl font-semibold mt-4'>Nhà triển lãm</span>
                                </div>
                            </div>
                            <div className='w-full mt-5 sm:w-1/4'></div>
                        </div>
                        <div className='mt-5 block sm:flex'>
                            <div className='w-full mt-5 sm:w-1/4'></div>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-primary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>10,000</h2>
                                    <span className='block text-xl font-semibold mt-4'>m<sup>2</sup> không gian triển lãm</span>
                                </div>
                            </div>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-primary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>300</h2>
                                    <span className='block text-xl font-semibold mt-4'>Cuộc giao thương B2B</span>
                                </div>
                            </div>
                            <div className='w-full mt-5 sm:w-1/4'>
                                <div className='p-3 rounded-md bg-primary text-center text-white w-5/6 mx-auto'>
                                    <h2 className='text-4xl font-semibold'>10</h2>
                                    <span className='block text-xl font-semibold mt-4'>Sự kiện chuyên ngành</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Featured Exhibitors 2024 */}
            <div className='mx-auto max-w-7xl mb-7 py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">ĐƠN VỊ TRIỂN LÃM TIÊU BIỂU</h2>
                    <FeaturedExhibitors list={ListFeaturedExhibitors} />
                    <div className='text-center my-5'>
                        <a href='/exhibitor-list' className='px-14 py-2 rounded-2xl text-xl bg-cyan-900 text-white font-semibold hover:bg-orange-500'>XEM THÊM</a>
                    </div>
                </div>
            </div>

            <div className='mx-auto hidden max-w-7xl mb-7 py-6 sm:px-6 lg:px-8'>
            </div>

            {/*THE MOST DIVERSIFIED CATEGORIES OF PRODUCTS */}
            <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                <SliderGallery />
            </div>

            <div className='bg-backdrop2 hidden' style={{ backgroundPosition: "50% 0%" }}>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                    <div className='section-box'>
                        <h2 className="py-6 text-4xl my-3 text-center font-bold tracking-wide text-color-primary">KHÁCH THAM QUAN</h2>
                        <div className='block px-6 sm:flex sm:flex-wrap'>
                            <div className='w-full overflow-hidden sm:w-1/3' style={{ height: "320px" }}>
                                <img src={img10} className='h-full object-cover' />
                            </div>
                            <div className='w-full sm:w-1/3 bg-white font-semibold py-6 px-6 box-border'>
                                <h3 className='text-2xl my-3 text-center'>KHÁCH CHUYÊN MÔN</h3>
                                <ul className='list-disc'>
                                    <li className='mt-2'>Nhà thiết kế, Kiến trúc sư, Nhà sáng tạo</li>
                                    <li className='mt-2'>Chuyên gia tiếp thị, Chuyên gia nghiên cứu thị trường</li>
                                    <li className='mt-2'>Trường, Viện thiết kế, kiến trúc, thời trang, đồ họa</li>
                                </ul>
                            </div>
                            <div className='w-full overflow-hidden sm:w-1/3' style={{ height: "320px" }}>
                                <img src={img12} className='h-full object-cover' />
                            </div>
                            <div className='w-full sm:w-1/3 bg-white font-semibold py-6 px-6 box-border'>
                                <h3 className='text-2xl my-3 text-center'>KHÁCH THƯƠNG MẠI</h3>
                                <ul className='list-disc'>
                                    <li className='mt-2'>Nhà phát triển dự án</li>
                                    <li className='mt-2'>Công ty xây dựng, Kiến trúc, Nội thất, Cty KD TTNT, VLXD</li>
                                    <li className='mt-2'>Các nhà sản xuất, Các cửa hàng bán lẻ</li>
                                </ul>
                            </div>
                            <div className='w-full overflow-hidden sm:w-1/3' style={{ height: "320px" }}>
                                <img src={img11} className='h-full object-cover' />
                            </div>
                            <div className='w-full sm:w-1/3 bg-white font-semibold py-6 px-6 box-border'>
                                <h3 className='text-2xl my-3 text-center'>KHÁCH THAM QUAN TỰ DO</h3>
                                <ul className='list-disc'>
                                    <li className='mt-2'>Khách tiêu dùng </li>
                                    <li className='mt-2'>Khách tham quan tự do</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
                    <div className='section-box'>
                        <h2 className="py-6 text-2xl sm:text-4xl my-3 text-center font-bold tracking-wide text-color-primary">HỘI ĐỒNG CỐ VẤN VIBE EXPO</h2>
                        <div className='block px-6'>
                            <p className='text-xl'>- Đóng vai trò cố vấn về concept, định hướng chuyên môn cho triển lãm & các hoạt động theo khuôn khổ triển lãm</p>
                            <p className='text-xl'>- Cố vấn chuyên môn cho các diễn đàn, sự kiện, giải thưởng và các hoạt động chuyên môn khác</p>
                            <p className='text-xl'>- Chủ trì và chia sẻ ở các sự kiện phù hợp</p>
                            <div className='mt-10'>
                                <h3 className='text-2xl px-10 py-3 inline-block bg-blue-900 text-white font-semibold'>Hiệp hội</h3>
                                <div className='flex flex-wrap'>
                                    <div className='w-full mt-10 md:w-1/3'>
                                        <div className='w-1/2 md:h-36 md:w-1/3 mx-auto flex items-center'>
                                            <img src={logoHawa} className='w-full' />
                                        </div>
                                        <p className='text-center mt-5 md:mt-1'>Hội Mỹ nghệ & Chế biến gỗ TP.HCM</p>
                                    </div>
                                    <div className='w-full mt-10 md:w-1/3'>
                                        <div className='w-1/2 md:h-36 md:w-1/3 mx-auto flex items-center'>
                                            <img src={logoSaca} className='w-full' />
                                        </div>
                                        <p className='text-center mt-5 md:mt-1'>Hiệp hội Xây dựng và Vật liệu xây dựng TP.HCM </p>
                                    </div>
                                    <div className='w-full mt-10 md:w-1/3'>
                                        <div className='w-1/2 md:h-36 md:w-1/3 mx-auto flex items-center'>
                                            <img src={logoVnia} className='w-full' />
                                        </div>
                                        <p className='text-center mt-5 md:mt-1'>Hội nội thất Việt Nam</p>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-10'>
                                <h3 className='text-2xl px-10 py-3 inline-block bg-blue-900 text-white font-semibold'>Chuyên gia</h3>
                                <div className='mt-10'>
                                    <Testimonials />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tin tuc/blog */}
            <div className='mx-auto max-w-7xl mb-7 py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">TIN TỨC - BLOG</h2>
                    <Events list={ListNews} />
                    <div className='text-center my-5'>
                        <a href='/news' className='px-14 py-2 rounded-2xl text-xl bg-cyan-900 text-white font-semibold hover:bg-orange-500'>XEM THÊM</a>
                    </div>
                </div>
            </div>

            {/*PARTNERS*/}
            <div className='mx-auto max-w-7xl mt-10 py-3 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <div className='block lg:flex'>
                        <div className='w-full'>
                            <h2 className="text-2xl sm:text-4xl text-center font-bold tracking-wide text-color-primary">ĐƠN VỊ ĐỒNG TỔ CHỨC</h2>
                            <div className='mt-10'>
                                <Partner />
                            </div>
                        </div>
                        <div className='w-full hidden lg:w-1/2'>
                            <h2 className="text-2xl sm:text-4xl text-center font-bold tracking-wide text-color-primary">ĐƠN VỊ ĐỒNG HÀNH</h2>
                            <div className='mt-3'>
                                <EndorsedBy />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mx-auto max-w-7xl mt-10 py-3 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <div className='block lg:flex'>
                        <div className='w-full'>
                            <h2 className="text-2xl sm:text-4xl text-center font-bold tracking-wide text-color-primary">ĐƠN VỊ PHỐI HỢP</h2>
                            <div className='mt-10'>
                                <Organizer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Slider Exhibitors 2024 */}
            <div className='mx-auto max-w-7xl mb-7 py-6 sm:px-6 lg:px-8'>
                <div className='section-box'>
                    <h2 className="py-6 text-2xl sm:text-4xl text-center font-bold text-color-primary tracking-wide mt-4">NHÀ TRIỂN LÃM TIÊU BIỂU</h2>
                    <SliderExhibitor />                    
                </div>
            </div>


            {show_ads && (
                <AdsPopup onClose={() => { setShow((prev) => ({ ...prev, show_ads: false })) }} />
            )}
        </>
    )
}

export default Home