import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const exhibitorApi = {
    get: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = ""
            if (req != null) {
                _url += `${process.env.REACT_APP_CODE}`;
                _url += "?session_id=" + session_id;
                _url += "&ex_id=" + req.exhibitorId;
            }
            var callApi = await api.get("exhibitor/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    edit: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = ""
            if (req != null) {
                _url += `${process.env.REACT_APP_CODE}`;
                _url += "?session_id=" + session_id;
                _url += "&ex_id=" + req.exhibitorId;
            }
            var callApi = await api.get("exhibitor/edit/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    update_info: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id) && req != null) {
            req.session_id = session_id;
            req["AppSite"] = process.env.REACT_APP_CODE;
            var callApi = await api.post("exhibitor/update_exhibitor", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    create_exhibitor: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id) && req != null) {
            req.session_id = session_id;
            req["AppSite"] = process.env.REACT_APP_CODE;
            var callApi = await api.post("exhibitor/create_exhibitor", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    register: async (req) => {
        //var session_id = CommonHelper.get_session_id();
        if (req != null) {
            //req.session_id = session_id;
            req["AppSite"] = process.env.REACT_APP_CODE;
            var callApi = await api.post("exhibitor/register", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    get_venues: async (season_id) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.get("exposeason/get_venues/" + `${process.env.REACT_APP_CODE}`);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    search: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id) && req != null) {
            var app = await CommonHelper.get_app();
            if (app != null) {
                req.season_id = app.seasonId;
                req["app_site"] = process.env.REACT_APP_CODE;
            }
            req.session_id = session_id;
            var callApi = await api.post("exhibitor/search", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    upload_logo: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        //if (CommonHelper.is_not_empty(session_id)) {
        const formData = new FormData();
        //formData.append("session_id", session_id);
        formData.append("app_site", process.env.REACT_APP_CODE);
        formData.append("is_present", 0);
        formData.append("exhibitor_id", req.exhibitor_id);
        formData.append("exhibitor_image", req.file)
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "exhibitor/upload_exhibitor_logo", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
        //}
        return null;
    },
    upload_image: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        //if (CommonHelper.is_not_empty(session_id)) {
        const formData = new FormData();
        //formData.append("session_id", session_id);
        formData.append("app_site", process.env.REACT_APP_CODE);
        formData.append("is_present", req.is_present);
        formData.append("exhibitor_id", req.exhibitor_id);
        formData.append("exhibitor_image", req.file)
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "exhibitor/upload_exhibitor_image", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
        //}
        return null;
    },
    get_list: async () => {
        var app = await CommonHelper.get_app();
        if (app != null) {
            var callApi = await api.get("exhibitor/get_list/" + process.env.REACT_APP_CODE);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return null;
    },
    get_exhibitor: async (name) => {
        var app = await CommonHelper.get_app();
        if (app != null) {
            var callApi = await api.get("exhibitor/get_exhibitor/" + name + "?appSite=" + process.env.REACT_APP_CODE);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return null;
    },
    del_product_image: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var req = {
                id, session_id
            };
            var callApi = await api.post("exhibitor/delete_product_image", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    check_existing_login_account: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id) && req != null) {
            req["AppSite"] = process.env.REACT_APP_CODE;
            req.session_id = session_id;
            var callApi = await api.post("exhibitor/check_existing_login_account", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    check_existing_exhibitor_name: async (req) => {
        //var session_id = CommonHelper.get_session_id();
        if (req != null) {
            var app = await CommonHelper.get_app();
            req["AppSite"] = process.env.REACT_APP_CODE;
            //req.session_id = session_id;
            var callApi = await api.post("exhibitor/check_existing_exhibitor_name", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
    get_exhibitor_in_home: async () => {
        var app = await CommonHelper.get_app();
        if (app != null) {
            var callApi = await api.get("exhibitor/get_exhibitor_in_home/" + process.env.REACT_APP_CODE);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return null;
    },
    del_exhibitor: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var req = {
                AppSite: process.env.REACT_APP_CODE,
                ExhibitorId: id,
                session_id
            };
            /*_url += "?id=" + id;
            _url += "&session_id=" + session_id;*/
            var callApi = await api.post("exhibitor/delete_exhibitor", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
    },
}

export default exhibitorApi;