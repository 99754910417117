import React from 'react'
import bgHeader from "../../../assets/slide-1.jpg"

const products = ['Living room Furniture', 'Dining room & Kitchen Furniture', 'Bedroom Furniture', 'Bathroom Furniture',
  'Children’s Furniture', 'Outdoor & Garden Furniture', 'Office Furniture', 'Upholstery Leather', 'Interior Decoration',
  'Gift & Handicraft', 'Bamboo Rattan Product', 'Carpet & Flooring', 'Hospitalities & Project Furniture'];
function ExhibitorAbout() {
  return (
    <>
      <div className='h-60' style={{ backgroundImage: `url(${bgHeader})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50% 50%" }}>
        <div className='h-full flex items-center' style={{ backgroundColor: "#0000005f" }}>
          <div className='w-full'>
            <h1 className='block text-4xl text-white text-center font-bold'>VỀ VIBE</h1>
          </div>
        </div>
      </div>
      <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
        <div className='section-box'>
          <h2 className="text-3xl font-bold tracking-tight text-color-primary mb-8">VỀ VIBE - TRIỂN LÃM NỘI THẤT VÀ XÂY DỰNG VIỆT NAM</h2>
          <p className='mt-2'>VIBE - nơi hội tụ của cảm hứng sáng tạo và xu hướng dẫn đầu trong ngành nội thất và xây dựng, lần đầu tiên ra mắt tại Việt Nam. Đây là sự kiện không thể bỏ lỡ cho những ai đam mê cái đẹp và sự bền vững, nơi mà không gian nội thất ấn tượng kết hợp cùng các giải pháp xây dựng vững chắc, tạo nên những sản phẩm và công trình xuất sắc với tính ứng dụng cao.</p>
          <h5 className='text-color-primary font-semibold text-xl mt-2'>Chi Tiết Sự Kiện</h5>
          <p className='mt-2'>Triển lãm Nội thất và Xây dựng - VIBE 2025 sẽ diễn ra từ ngày 02 đến 05/10/2024 tại Trung tâm Triển lãm và Hội nghị SECC - 799 Nguyễn Văn Linh, P. Tân Phú, Quận 7. Sự kiện quy tụ hơn 650 gian hàng từ 250 doanh nghiệp hàng đầu trong lĩnh vực nội thất, kiến trúc và xây dựng, dự kiến thu hút hơn 12,000 khách tham quan trong nước và quốc tế.</p>
          <h5 className='text-color-primary font-semibold text-xl mt-2'>Bối Cảnh Ra Đời</h5>
          <p className="mt-2">
            Trong bối cảnh thị trường vật liệu xây dựng trong nước gặp nhiều thách thức, nhưng xuất khẩu vẫn có dấu hiệu khởi sắc, cùng với sự phục hồi của thị trường gỗ và nội thất, và dòng vốn FDI trong lĩnh vực xây dựng đang tăng trưởng mạnh mẽ, VIBE ra đời như một nền tảng giao thương toàn diện. Sự kiện này không chỉ giúp đẩy mạnh giá trị ngành, mà còn tạo ra giải pháp bền vững để phát triển thị trường nội địa và xuất khẩu.
          </p>
          <h5 className='text-color-primary font-semibold text-xl mt-2'>05 Giá Trị Khác Biệt Tại Triển Lãm VIBE</h5>
          <ul className='mt-5 list-disc ms-5'>
            <li><strong>Giải Pháp Toàn Diện: </strong>Cung cấp các giải pháp về vật liệu, xây dựng và nội thất đáp ứng các tiêu chí Style, Smart và Sustainability, phục vụ cả thị trường nội địa và xuất khẩu.</li>
            <li><strong>Quy Hoạch Bài Bản: </strong>Tuyển chọn doanh nghiệp tham gia theo lĩnh vực và ngành nghề, đảm bảo sự đa dạng và chất lượng.</li>
            <li><strong>Kết Nối Hệ Sinh Thái: </strong>Tạo dựng mạng lưới kết nối giữa các doanh nghiệp B2B và B2C trong ngành xây dựng và nội thất.</li>
            <li><strong>Dẫn Dắt Xu Hướng: </strong>Định hướng cho nhà sản xuất và người tiêu dùng về những xu hướng mới nhất trong ngành.</li>
            <li><strong>Ban Giám Tuyển Tài Năng: </strong>Đội ngũ ban tổ chức giàu kinh nghiệm và hiểu biết sâu sắc về thị trường, đảm bảo sự chuyên nghiệp và chất lượng của triển lãm.</li>
          </ul>
          <h5 className='text-color-primary font-semibold text-xl mt-2'>Giá Trị Cốt Lõi Tại Triển Lãm VIBE</h5>
          <ul className='mt-5 list-disc ms-5'>
            <li><strong>STYLE: </strong>Khám phá những thiết kế nội thất đẹp mắt và có phong cách từ các nhà thiết kế và thương hiệu hàng đầu. Triển lãm giúp khách tham quan định hình phong cách cá nhân thông qua những sản phẩm ứng dụng cao.</li>
            <li><strong>SMART: </strong>Kết hợp công nghệ thông minh trong kiến trúc và xây dựng để tạo nên những sản phẩm và công trình chất lượng cao của tương lai.</li>
            <li><strong>SUSTAINABILITY: </strong>Tập trung vào thiết kế, sản xuất và sử dụng sản phẩm hướng tới phát triển bền vững, xanh hóa và thực hành ESG.</li>
          </ul>
          <p className='mt-5'>Đừng bỏ lỡ cơ hội khám phá phong cách riêng và những xu hướng thiết kế hàng đầu trong lĩnh vực nội thất, kiến trúc và xây dựng tại VIBE 2025.</p>
          <p className='mt-5'>Theo dõi thông tin hội chợ tại:</p>
          <ul className='mt-5 list-disc ms-5'>
            <li><strong>Website: </strong><a href='http://thevibexpo.com'>http://thevibexpo.com/</a></li>
            <li><strong>Facebook: </strong><a target='_blank' href='https://www.facebook.com/Vibexpo'>https://www.facebook.com/Vibexpo</a></li>
            <li><strong>Instagram: </strong><a target='_blank' href='https://www.instagram.com/vibe_expo_vietnam/'>https://www.instagram.com/vibe_expo_vietnam</a></li>
            <li><strong>LinkedIn: </strong><a target='_blank' href='https://www.linkedin.com/company/vibe-vietnam-interior-build-expo/'>https://www.linkedin.com/company/vibe-vietnam-interior-build-expo</a></li>
            <li><strong>Email: </strong><a target='_blank' href='mailTo:info@thevibexpo.com'>info@thevibexpo.com</a></li>
          </ul>
          <p className='mt-5'>Hãy cùng chúng tôi trải nghiệm và cảm nhận VIBE - nơi khởi nguồn cho những ý tưởng sáng tạo và bền vững trong lĩnh vực nội thất và xây dựng.</p>
          {/* <div className='mt-3'>
            <ul className='list-disc ms-5'>
              {products.map((item, i) => (
                <li className='mt-2' key={i}>{item}</li>
              ))}
            </ul>
          </div>

          <p className='mt-5'><strong>VENUE &amp; DATE:&nbsp;</strong> Vibe 2024 takes pride in presenting a panoramic view and boundaryless exploration through its <strong>02 BESPOKE LOCATIONS.</strong> Located at the most prestigious and largest expo halls in Ho Chi Minh City, each venue offers a unique perspective on Vietnam’s thriving furniture industry</p>
          <ul className='mt-5'>
            <li><strong>CREATE HALL: </strong>A hub of interior design &amp; innovation</li>
          </ul>
          <p className='mt-5'><strong>07 – 09 March 2024</strong> | White Palace – 588 Pham Van Dong Street, Thu Duc City, Ho Chi Minh City</p>
          <ul className='mt-5'>
            <li><strong>CONNECT HALL: </strong>A hub of Vietnam furniture’s entire supply chain</li>
          </ul>
          <p className='mt-5'><strong>06 – 09 March 2024</strong> | SECC, 799 Nguyen Van Linh, Tan Phu Ward, Dist. 7, Ho Chi Minh City Join us at the Vibe and experience the future of furniture!</p>
          <ul className='mt-5'>
            <li><strong>Hotline:</strong> (+84) 987 980 859</li>
            <li><strong>Email:</strong>&nbsp;<a href="mailto:info@vibe.com">info@vibe.com</a></li>
            <li><strong>Facebook</strong>: <a href="https://www.facebook.com/vibe" rel="nofollow noopener" target="_blank">https://www.facebook.com/vibe</a></li>
          </ul> */}
        </div>
      </div>
    </>
  )
}

export default ExhibitorAbout