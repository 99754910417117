import React, { useState, useEffect } from 'react'
import { addSeconds, addDays } from 'date-fns';
import { useCountdown } from '../../helpers/useCountdown'
import CommonHelper from '../../helpers/common'


function CountDownTime() {
    var _evt_dt = CommonHelper.parseStringToDate("20241002 080000", "yyyyMMdd HHmmss")
    const [weeks, days, hours, minutes, seconds] = useCountdown(_evt_dt)
    return (
        <>
            <div className='flex text-white uppercase relative'>
                <div className='w-1/3 rounded-lg bg-primary mx-2 px-3 py-3 box-border text-center'>
                    <span className='block font-bold text-2xl sm:text-5xl'>{weeks}</span>
                    <span className='block font-semibold text-xs sm:text-sm'>Weeks</span>
                </div>
                <div className='w-1/3 rounded-lg bg-primary mx-2 px-3 py-3 box-border text-center'>
                    <span className='block font-bold text-2xl sm:text-5xl'>{days}</span>
                    <span className='block font-semibold text-xs sm:text-sm'>Days</span>
                </div>
                <div className='w-1/3 rounded-lg bg-primary mx-2 px-3 py-3 box-border text-center'>
                    <span className='block font-bold text-2xl sm:text-5xl'>{hours}</span>
                    <span className='block font-semibold text-xs sm:text-sm'>Hours</span>
                </div>
                {/* <div className='w-1/5 rounded-lg bg-primary mx-2 px-3 py-3 box-border text-center'>
                    <span className='block font-bold text-2xl sm:text-5xl'>{minutes}</span>
                    <span className='block font-semibold text-xs sm:text-sm'>Minutes</span>
                </div>
                <div className='w-1/5 rounded-lg bg-primary mx-2 px-3 py-3 box-border text-center'>
                    <span className='block font-bold text-2xl sm:text-5xl'>{seconds}</span>
                    <span className='block font-semibold text-xs sm:text-sm'>Seconds</span>
                </div> */}
            </div>
        </>
    )
}

export default CountDownTime