import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLoaderData } from 'react-router-dom'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import ExhibitorGallery from './component/gallery'
import exhibitorApi from '../../../api/exhibitorApi'
import Loading from '../../../components/controls/loading'
import CommonHelper from '../../../helpers/common'
import { Helmet } from 'react-helmet'


export async function loader({ params }) {
    var response = await exhibitorApi.get_exhibitor(params.id);
    if (response != null) {
        return response;
    }
    return null;
}



const data = {
    "title": "KODA SAIGON CO., LTD",
    "location": "VIETNAM",
    "products": ['Office Furniture', 'Living Room Furniture', 'Dining Room Furniture',],
    "markets": ['Vietnam', 'USA', 'Japan', 'China',],
    "materials": ['Acacia', 'Rubber Wood', 'Cedar', 'Teak',],
    "certificates": ['CTPAT', 'CITES', 'GREENGUARD', 'IAQ', 'ZWL',],
    "address": "67 Tran Nao Street, An Khanh Ward, Thu Duc City (District 2), Ho Chi Minh City, Vietnam",
    "factory_address": "N/A",
    "email": "lucy.pham@pro-living.asia",
    "phone": "+84703633603",
    "website": "https://pro-living.asia/"
};

function ExhibitorDetail() {
    const [state, setState] = useState(data);
    const [{ isLoading }, setCommonState] = useState({
        isLoading: true
    })
    const exhibitorData = useLoaderData();
    useEffect(() => {
        if (exhibitorData != null && exhibitorData.code == 200) {
            var _data = exhibitorData.data;
            var products = [];
            if (CommonHelper.is_not_empty(_data.productTypes)) {
                products = JSON.parse(_data.productTypes);
            }
            var markets = [];
            if (CommonHelper.is_not_empty(_data.exportMarkets)) {
                markets = JSON.parse(_data.exportMarkets);
            }
            var certificates = [];
            if (CommonHelper.is_not_empty(_data.certificates)) {
                certificates = JSON.parse(_data.certificates);
            }
            var materials = [];
            if (CommonHelper.is_not_empty(_data.mainMaterial)) {
                materials = JSON.parse(_data.mainMaterial);
            }
            _data = { ..._data, products, markets, certificates: certificates, materials };
            console.log(_data)
            if (_data.exhibitorImages == null) _data.exhibitorImages = [];
            setState(_data);
        }
        const timer = setTimeout(() => {
            setCommonState((prev) => ({ ...prev, isLoading: false }));
        }, 1000);
        return () => clearTimeout(timer);
    }, [exhibitorData])
    return (
        <>
            {state != null && (
                <Helmet>
                    <title>{`thevibexpo - ${state.companyNameVn} - ${state.companyNameEn}`}</title>
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={`${state.companyNameVn} - ${state.companyNameEn}`} />
                    <meta property="og:description" content={`${state.description}`} />
                    <meta property="og:url" content={"https://thevibexpo.com/exhibitor/" + state.extraValue5} />
                    <meta property="og:site_name" content="Thevibexpo" />
                    <meta property="article:publisher" content="https://www.facebook.com/Vibexpo" />
                    <meta property="article:section" content="Chưa phân loại" />
                    <meta property="og:image" content={process.env.REACT_APP_EXHIBITOR_IMAGE + state.imageUrl} />
                    <meta property="og:image:secure_url" content={process.env.REACT_APP_EXHIBITOR_IMAGE + state.imageUrl} />
                    <meta property="og:image:width" content="1496" />
                    <meta property="og:image:height" content="1566" />
                    <meta property="og:image:alt" content={`${state.companyNameVn} - ${state.companyNameEn}`} />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="article:published_time" content="2024-03-26T16:59:19+07:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${state.companyNameVn} - ${state.companyNameEn}`} />
                    <meta name="twitter:description" content={`${state.description}`} />
                    <meta name="twitter:image" content={process.env.REACT_APP_EXHIBITOR_IMAGE + state.imageUrl} />
                    <meta name="twitter:label1" content="Written by" />
                    <meta name="twitter:data1" content="admin" />
                    <meta name="twitter:label2" content="Time to read" />
                    <meta name="twitter:data2" content="4 minutes" />
                </Helmet>
            )}
            <div className='mx-auto max-w-7xl py-2 sm:px-6 lg:px-8 '>
                <div className='py-2'>
                    <Link to={"/exhibitor-list"} className='italic text-xs'>
                        <FontAwesomeIcon icon={faChevronLeft} /> Quay về Nhà triển lãm</Link>
                </div>
                <div className='block sm:flex'>
                    <div className='block sm:w-1/2'>
                        <div className='h-96'>
                            {state.exhibitorImages != null && state.exhibitorImages.length > 0 && (<ExhibitorGallery list={state.exhibitorImages} />)}
                        </div>
                    </div>
                    <div className='block sm:w-1/2 sm:ps-20 px-2 box-border'>
                        <h1 className='text-2xl font-semibold text-color-primary'>{state.companyNameVn}</h1>
                        <div className='py-1 mt-2 block sm:flex'>
                            <div className='block w-full sm:w-1/5'>Ngành hàng:</div>
                            <div className='block w-full sm:w-4/5'>
                                {state.products.length > 0 && (
                                    <>{state.products.join(", ")}</>
                                )}
                            </div>
                        </div>
                        {/* <div className='py-1 block sm:flex'>
                            <div className='block w-full sm:w-1/5'>Export markets:</div>
                            <div className='block w-full sm:w-4/5'>
                                {state.markets.length > 0 && (
                                    <>{state.markets.join(", ")}</>
                                )}
                            </div>
                        </div> */}
                        {state.materials.length > 0 && (
                            <div className='py-1 block sm:flex'>
                                <div className='block w-full sm:w-1/4'>Sản phẩm chính:</div>
                                <div className='block w-full sm:w-3/4'>
                                    <>{state.materials.join(", ")}</>
                                </div>
                            </div>
                        )}
                        {state.certificates.length > 0 && (
                            <div className='py-1 block sm:flex'>
                                <div className='block w-full sm:w-1/5'>Chứng chỉ:</div>
                                <div className='block w-full sm:w-4/5'>
                                    <>{state.certificates.join(", ")}</>
                                </div>
                            </div>
                        )}

                        <div className="mt-2">
                            <p>Mô tả Doanh nghiệp:</p>
                            <p className='py-2'>{state.description}</p>
                        </div>
                        <div className='mt-2'>
                            <h5 className='text-2xl font-semibold text-color-primary mb-4'>Thông tin Doanh nghiệp:</h5>
                            <p >
                                <span className='font-semibold inline-block'>Địa chỉ: &nbsp;</span>
                                <span className='inline-block'>{state.addressEn}</span>
                            </p>
                            {CommonHelper.is_not_empty(state.factoryAddressEn) && (
                                <p >
                                    <span className='font-semibold inline-block'>Địa chỉ nhà máy: &nbsp;</span>
                                    <span className='inline-block'>{state.factoryAddressEn}</span>
                                </p>
                            )}
                            {CommonHelper.is_not_empty(state.contactEmail) && (
                                <p >
                                    <span className='font-semibold inline-block'>Email: &nbsp;</span>
                                    <span className='inline-block'>
                                        <a href={"mailTo:" + state.contactEmail}>{state.contactEmail}</a>
                                    </span>
                                </p>
                            )}
                            <p className='hidden'>
                                <span className='font-semibold inline-block'>Phone: &nbsp;</span>
                                <span className='inline-block'>
                                    <a href={"tel:" + state.contactPhone}>{state.contactPhone}</a>
                                </span>
                            </p>
                            <p >
                                <span className='font-semibold inline-block'>Website: &nbsp;</span>
                                <span className='inline-block fs-color-secondary'>
                                    <a href={'http://' + state.website} target='_blank'>{state.website}</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <a href={"/exhibitor-profile"}
                        className='px-3 py-1 border-cyan-900 border-2 text-color-primary text-xl font-semibold hover:text-orange-500'>
                        CẬP NHẬT HỒ SƠ DOANH NGHIỆP</a>
                </div>
                <div className='h-10'></div>
            </div>
        </>
    )
}

export default ExhibitorDetail