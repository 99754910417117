import api from "./api";
import axios from "axios";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const eventApi = {
    get: async () => {
        var _url = `${process.env.REACT_APP_CODE}`;
        var callApi = await api.get("event/" + _url);
        if (callApi.code === 401) {
            console.log(callApi);
            return { code: 506 }
        }
        return callApi;
    },
    get_event: async (id) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = `${process.env.REACT_APP_CODE}`;
            _url += "?id=" + id;
            _url += "&session_id=" + session_id;
            var callApi = await api.get("event/get_event/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
        }
        return callApi;
    },
    get_list: async (req) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            req = {
                ...req,
                session_id,
                AppSite: `${process.env.REACT_APP_CODE}`
            }
            /*var _url = `${process.env.REACT_APP_CODE}`;
            _url += "?session_id=" + session_id;*/
            var callApi = await api.post("event/get_list", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    post: async (req) => {
        //req["AppSite"] = process.env.REACT_APP_CODE;
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            req = {
                ...req, session_id,
                AppSite: process.env.REACT_APP_CODE
            };
            var callApi = await api.post("event", req);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    get_event_by_url: async (val) => {
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var _url = `${process.env.REACT_APP_CODE}`;
            _url += "&url=" + val;
            var callApi = await api.get("event/get_event_by_url/" + _url);
            if (callApi.code === 401) {
                console.log(callApi);
                return { code: 506 }
            }
            return callApi;
        }
        return { code: 506 }
    },
    upload_event_image_to_editor: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        if (CommonHelper.is_not_empty(session_id)) {
            const formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("app_site", process.env.REACT_APP_CODE);
            formData.append("event_image", req.file)
            var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "event/upload_event_image_to_editor", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            return response;
        }
        return null;
    },
    upload_event_image: async (req) => {
        var token = await CommonHelper.get_token();
        var app = await CommonHelper.get_app();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        if (CommonHelper.is_not_empty(session_id)) {
            const formData = new FormData();
            formData.append("session_id", session_id);
            formData.append("app_site", process.env.REACT_APP_CODE);
            formData.append("event_id", req.event_id);
            formData.append("event_image", req.file)
            var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "event/upload_event_image", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            return response;
        }
        return null;
    },
}

export default eventApi;