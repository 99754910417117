import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import logo4 from '../../assets/logo-vnia.jpg'
import logo5 from '../../assets/logo-consmedia.jpg'

// import required modules
import { Autoplay, Keyboard, Navigation, EffectFade, Pagination } from 'swiper/modules';

const data = [
    {
        "img_url": "https://www.hawaexpo.com/wp-content/uploads/2022/10/5.png",
        "title": "Handicraft & Wood Industry Association of HCMC (HAWA)",
    },
    {
        "img_url": "https://www.hawaexpo.com/wp-content/uploads/2023/03/bifa-2-01-e1679468567459-800x243.png",
        "title": "Binh Duong Furniture Association (BIFA)",
    },
    {
        "img_url": "https://www.hawaexpo.com/wp-content/uploads/2022/10/viforest.png",
        "title": "Vietnam Timber & Forest Products Association (VIFORES)",
    },
    {
        "img_url": "https://www.hawaexpo.com/wp-content/uploads/2022/10/DOWA-logo-2022.png",
        "title": "Dong Nai Wood and Handicraft Association (DOWA)",
    },
    {
        "img_url": "https://www.hawaexpo.com/wp-content/uploads/2022/10/Logo_FPABinhDinh_s-800x348.png",
        "title": "FOREST PRODUCTS ASSOCIATION OF BINH DINH (FPA)",
    },
]

function Organizer() {
    return (
        <>
            <div>
                <Swiper
                    slidesPerView={1}
                    // autoHeight={true}
                    height={144}
                    spaceBetween={60}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={'fade'}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Keyboard, Navigation]}
                    className="mySwiper"
                >
                    {/*<Slider /> */}
                    <SwiperSlide>
                        <div className='flex items-center h-40'>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'>
                                    <img src={logo4} className='w-full object-cover' />
                                </div>
                            </div>
                            <div className='w-1/2 p-3'>
                                <div className='w-2/3 xl:w-1/3 mx-auto'><img src={logo5} className='w-full object-cover' /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Organizer