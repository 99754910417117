import React from 'react'
import CommonHelper from '../../helpers/common'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import img1 from '../../assets/nguyen-quoc-khanh.png'
import img2 from '../../assets/hoang-thuc-hao.png'
import img3 from '../../assets/nguyen-hoang-manh.png'
import img4 from '../../assets/dinh-hong-ky.png'
import img5 from '../../assets/nguyen-phan-thuy-duong.png'
import img6 from '../../assets/vuong-dao-hoang.png'
import img7 from '../../assets/nguyen-chanh-phuong.png'

const data = [
    {
        "img": img1,
        "name": "Ông Nguyễn Quốc Khanh",
        "title": "Chủ tịch AA Corporation<br/>Chủ tịch HAWA",
    },
    {
        "img": img2,
        "name": "Ông Hoàng Thúc Hào",
        "title": "Kiến trúc sư",
    },
    {
        "img": img3,
        "name": "Ông Nguyễn Hoàng Mạnh",
        "title": "Kiến trúc sư - CEO Mia Design",
    },
    {
        "img": img4,
        "name": "Ông Đinh Hồng Kỳ",
        "title": "Chủ tịch HĐQT Cty CP Secoin<br/>Phó chủ tịch SACA",
    },
    {
        "img": img5,
        "name": "Bà Nguyễn Phan Thùy Dương",
        "title": "Chủ biên tạp chí ELLE Decoration",
    },
    {
        "img": img6,
        "name": "Ông Vương Đạo Hoàng",
        "title": "Kiến trúc sư, Giám Đốc Kiến Việt Media",
    },
    {
        "img": img7,
        "name": "Ông Nguyễn Chánh Phương",
        "title": "Phó chủ tịch Cty AKA Furniture<br/>Phó chủ tịch HAWA",
    },
]

function Testimonials() {
    return (
        <>
            <div>
                {/* <div className="hidden xl:block">
                    <div className='flex'>
                        {data.map((item, i) => {
                            return (
                                <div className='flex-1' key={i}>
                                    <div className='mx-auto w-32 h-32 overflow-hidden mt-10' >
                                        <img className='object-fill' src={item.img} />
                                    </div>
                                    <p className='mt-4 px-2 text-xs'>{item.name}</p>
                                    <p className='px-2 text-xs'>{item.title}</p>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                <div className='hidden md:block'>
                    <div className='section-customers'>
                        <Swiper watchSlidesProgress={true} slidesPerView={4}
                            navigation={true} autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="mySwiper">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='mx-auto w-5/6 h-56 overflow-hidden mt-10' >
                                            <img className='object-fill' src={item.img} />
                                        </div>
                                        <p className='mt-4 px-2'>{item.name}</p>
                                        <p className='px-2'>{CommonHelper.convertStringToHTML(item.title)}</p>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className="block md:hidden">
                    <div className='section-customers'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="mySwiper">
                            {data.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className='mx-auto w-32 h-32 overflow-hidden mt-10' >
                                            <img className='object-fill' src={item.img} />
                                        </div>
                                        <p className='mt-4 px-2 '>{item.name}</p>
                                        <p className='px-2 '>{CommonHelper.convertStringToHTML(item.title)}</p>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Testimonials