import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import SImage from '../../assets/sq-9.jpg'

// import required modules
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import CommonHelper from '../../helpers/common';


function FeaturedExhibitors(props) {
    const { list, ...other } = props
    const [state, setState] = useState(null);
    useEffect(() => {
        setState(list)
    }, [list])
    return (
        <>
            {state != null && state.length > 0 && (
                <>
                    <div className='hidden md:block'>
                        <Swiper watchSlidesProgress={true} slidesPerView={4}
                            navigation={true} pagination={{
                                clickable: true,
                            }} autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-sm'
                                            href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                            <div className='rounded-lg w-full h-auto md:h-52 mb-2 flex items-center overflow-hidden'>
                                                {/* <img src={SImage} /> */}
                                                <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                            </div>
                                            <div className='p-content p-2 text-left text-color-primary'>
                                                <h5 className='text-1xl font-bold'>{item.companyNameVn} </h5>
                                                {/* <p className='mt-1'>HALL A1-ACACIA-AA10,AA12</p> */}
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='md:hidden'>
                        <Swiper watchSlidesProgress={true} slidesPerView={2}
                            navigation={true} pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Keyboard, Pagination, Navigation]} className="myWork">
                            {state.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className='block section-featured-exhibitor m-2 rounded-sm'
                                            href={"/exhibitor/" + (CommonHelper.is_not_empty(item.extraValue5) ? item.extraValue5 : item.companyNameVn)}>
                                            <div className='rounded-lg w-full h-36 sm:h-52 mb-2 flex items-center overflow-hidden'>
                                                <img className='object-cover' src={process.env.REACT_APP_EXHIBITOR_IMAGE + item.imageUrl} title={item.companyNameVn} />
                                            </div>
                                            <div className='p-content p-2 text-left text-color-primary'>
                                                <h5 className='md:text-xl font-bold'>{item.companyNameVn}</h5>
                                                {/* <p className='mt-1'>HALL A1-ACACIA-AA10,AA12</p> */}
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            )}

        </>
    )
}

export default FeaturedExhibitors