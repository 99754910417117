import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons'
import CommonHelper from '../../helpers/common'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Account(props) {
    const { isAuthenticated, onLogout, ...other } = props;
    const [state, setState] = useState(false);
    useEffect(() => {
        setState(isAuthenticated);
    }, [isAuthenticated])
    const logout = () => {
        onLogout();
    }
    return (
        <>
            {!state && (
                <div className='flex bg-primary ms-5 h-16 items-center text-center text-white'>

                    <div className='w-2/5 hidden'>
                        <Link to={"/login"} className="block hover:underline after:content-['/'] after:ml-0.5 after:float-end">Login</Link>
                    </div>
                    <div className='w-1/5'></div>
                    <div className='w-2/5'><Link to={"/register"} className=' hover:underline'>Register</Link></div>
                    <div className='w-1/5'>
                        <FontAwesomeIcon icon={faUser} className='text-white text-xl' />
                    </div>
                    <div className='w-1/5'></div>
                </div>
            )}
            {state && (
                <div className='flex items-center'>
                    <div className='w-1/2'></div>
                    <div className='flex w-1/2 bg-primary h-16 text-center items-center text-white'>
                        {/* <a className='block w-full'>
                            <FontAwesomeIcon icon={faUser} className=' text-xl' />
                            <span className='ms-2'>
                                <FontAwesomeIcon icon={faChevronDown} className='text-xs' />
                            </span>
                        </a> */}
                        <Menu as="div" className="relative inline-block text-left w-full">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center bg-none px-3 py-2 ">
                                    <FontAwesomeIcon icon={faUser} className=' text-xl' />
                                    <span className='ms-2'>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-xs' />
                                    </span>
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a href={"/exhibitor-profile"}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}>Cập nhật hồ sơ doanh nghiệp</a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className='py-1'>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button type="button" onClick={logout}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block w-full px-4 py-2 text-left text-sm'
                                                    )}
                                                >
                                                    Sign out
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            )}
        </>
    )
}

export default Account